import React from "react";
import './index.scss'
import {hideLoading, showLoading} from "@/components/loading";
import Service from "@/view/woodGrainBadge/service";
import {Toast} from "antd-mobile";

const ConfirmBuyBackModal = ({ onClose, selectNftNo, successCallBack }) => {

  const handleSubmit = async () => {
    try {
      showLoading();
      const res = await Service.buyBackService({nftNoList: selectNftNo});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      Toast.show({
        content: '回购成功，请在回购记录查看',
        afterClose: successCallBack,
      });
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  return (
    <div className="modalContainer">
      <div className="box">
        <div className="title">确认回购藏品</div>
        <div className="content">
          <div>确认将选中藏品回购，获得{selectNftNo?.length * 1000}积</div>
          <div>分？确认后藏品将不再持有。</div>
        </div>
        <div className="btnBox">
          <button className="cancelBtn" onClick={onClose}>取消</button>
          <button className="confirmBtn" onClick={handleSubmit}>确认回购</button>
        </div>
      </div>
    </div>
  )
}
export default ConfirmBuyBackModal