import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./style.scss";
import { Mask } from "antd-mobile";
import { list } from "postcss";

const SuccessModal = ({ onClose, leftCB, rightCB, collectionInfo = [] }) => {
  const [visible, setVisible] = useState(true);
  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    leftCB && leftCB();
  };

  const confirm = () => {
    rightCB && rightCB();
    setVisible(false);
    onClose && onClose();
  };

  return (
    <Mask
      className="compose-success-mask"
      visible={visible}
      onMaskClick={() => cancel()}
    >
      <div className="mask-content">
        <div className="title">支付成功</div>
        <div className="collection-info">
          {collectionInfo.map((item, index) => {
            return (
              <div className="img-box-out" key={index}>
                <div className="img-box">
                  <img src={item?.image} alt="" />
                </div>
                <span>{item?.collectionName}</span>
              </div>
            );
          })}
        </div>
        <div className="btn-box">
          <div className="cancel-btn common" onClick={() => cancel()}>
            确认
          </div>
        </div>
      </div>
    </Mask>
  );
};

SuccessModal.open = ({ collectionInfo, leftCB, rightCB }) => {
  let div = null;
  if (!div) {
    div = document.createElement("div");
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  };
  ReactDOM.render(
    <SuccessModal
      onClose={close}
      collectionInfo={collectionInfo}
      leftCB={leftCB}
      rightCB={rightCB}
    />,
    div
  );
};

export default SuccessModal;
