/**
 * @description 底部操作按钮（转赠、减时卡、合成）
*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import {useNavigate} from "react-router";
import { Popup, Toast } from "antd-mobile";
import classNames from "classnames";
import ModalAlert from "@/components/ModalAlert";
import {showLoading, hideLoading} from '@/components/loading';
import ColCompoundHome from "@/view/colCompound/home";
import DonationModal from "@/components/donationModal";
import {getTime} from '@/utils/publicFun';
import ReduceCard from '../reduceCard';
import {
    remainTimeCardUse,
    getHcStatus,
    getRemainTimeCardList,
    getUserCollectionsSetting,
    recoveryActivityExec
} from '@/api/projectManager_self';
import './index.scss';

import reduceImg from '@/assets/img/collection/reduce_card.png';
import imgSynthesis from '@/assets/img/img_synthesis.png';

const CodeMap = {
    207: 'healthPreservation', //康养藏品
}

const limitNum = (remainDays) => {
    let limit = 0;
    if (remainDays <= 5) {
        limit = 1;
    } else if (remainDays > 5 && remainDays <= 10) {
        limit = 2;
    } else if (remainDays > 10 && remainDays <= 15) {
        limit = 3;
    } else if (remainDays > 15 && remainDays <= 20) {
        limit = 4;
    } else if (remainDays > 20 && remainDays <= 25) {
        limit = 5;
    } else if (remainDays > 25 && remainDays <= 30) {
        limit = 6;
    }
    return limit;
}

const BeautifulNumBtnText = {
    0: '使用',
    1: '取消使用',
    2: '审核中',
    3: '未通过，重新提交',
  }

const Footer = (props = {}) => {
    const { 
        nft_hash, detailList, spHeChengInfo={}, hcStatus={}, 
        onUseBeautifulNumBtn, onRefesh, gemsFee, gemsCount
    } = props;

    const navigate = useNavigate()

    const [modalVisible, setModalVisible] = useState(false);
    const [cardVisible, setCardVisible] = useState(false);
    const [list, setList] = useState([]);
    const [chooseArr, setChooseArr] = useState([]);
    const [reduceDays, setReduceDays] = useState(0);
    const [pwdVisible, setPwdVisible] = useState(false);
    const [cardModalVisible, setCardModalVisible] = useState(false);
    const [colCompHomeVisible, setColCompHomeVisible] = useState(false);
    const [usedDays, setUsedDays] = useState(0);
    const [targetNftNoModalVisible, setTargetNftNoModalVisible] = useState(false);//关联藏品转赠弹窗

    useEffect(() => {
        let num = 0;
        let limit;
        if (!chooseArr.length) {
            const newList = list?.map((l) => {
                return {...l, isAvailable: false};
            })
            num = 0;
            setReduceDays(num);
            setList(newList);
            return;
        }
        ;
        num = chooseArr.reduce((pre, curr) => {
            return pre += curr.reduceDay;
        }, 0);
        if (Number(list[0]?.accumulateDays) === 30) {
            limit = chooseArr.length === limitNum(detailList?.remainDays);
        } else {
            limit = num === (detailList?.remainDays < (Number(list[0]?.accumulateDays) - Number(usedDays)) ? limitNum(detailList?.remainDays) * list[0]?.reduceDay : (Number(list[0]?.accumulateDays) - Number(usedDays)));
        }
        if (limit) {
            const arr = chooseArr.map((c) => c.timeReduceId)
            const newList = list.map((l) => {
                return {...l, isAvailable: arr.includes(l.timeReduceId) ? false : true};
            })
            setList(newList);
        } else {
            const newList = list?.map((l) => {
                return {...l, isAvailable: false};
            })
            setList(newList);
        }
        setReduceDays(num);
    }, [chooseArr])

    const getReduceTimeCard = async () => {
        try {
            showLoading();
            const params = {
                nftNo: detailList?.nftNo || '',
                collectionId: detailList?.collectionId || '',
            }
            const res = await getRemainTimeCardList(params);
            // const res = await mockCardFn();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let newList = [];
            if (res?.data?.timeReduceCardInfoList?.length > 0) {
                newList = res.data.timeReduceCardInfoList.map((item) => {
                    return {
                        ...item,
                        isAvailable: Number(res.data.usedDays) === Number(item.accumulateDays) ? true : false
                    };
                })
            }
            setList(newList);
            setUsedDays(res.data.usedDays || 0);
            setCardVisible(true);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const hcClick = async () => { //合成
        if (hcStatus.status !== 1) return;
        try {
            showLoading();
            const {code, msg, data} = await getHcStatus({nftHash: nft_hash});
            hideLoading();
            if (code === '000000') {
                if (data && data === 1) {
                    const uri = `/eggHatch?nftNo=${detailList?.nftNo}&collectionGroup=${detailList.collectionGroup}`
                    if(window.JsBridge.hasWebViewBridge()) {
                        window.JsBridge.replace("CustomWeb", {uri: `${window.location.origin}${uri}`});
                    }else {
                        navigate(uri);
                    }
                } else {
                    Toast.show('您当前已孵化合成过,请在我的藏品列表刷新数据查看');
                }
            } else {
                Toast.show(msg);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    // 康养合成
    const onKangyangMerge = ()=> {
        const content = (
            <div style={{padding: '20px 0', textAlign:'center'}}>
                恭喜集齐，合成后将获得 45 平米康养公寓永久使用权，客服将第一时间联系您完成线下签约，确认合成？
            </div>
        )
        ModalAlert.show({
            title: '确认合成',
            content: content,
            onAction: async (btn, index) => {
                if(btn.key === 'confirm') {
                    showLoading();
                    // 调用合成接口
                    const res = await recoveryActivityExec({activityCode: CodeMap[detailList?.collectionId || '']})
                    hideLoading();
                    if(res.code === '000000') {
                        ModalAlert.hide();
                        const list = res.data?.userCollectionRespList ?? [];
                        const collectionList = list.map(item=> {
                            return {
                                nftNo: item.elementId,
                                collectionName: '康养数字藏品',
                                image: item.url,
                                hash: item.hash,
                                collectionId: item.collectionId
                            }
                        })
                        Toast.show({
                            content: res.msg ?? '合成成功',
                            afterClose: () => {
                                const url = '/ape/PaySuccess'
                                navigate(url, { 
                                    state: {
                                        collectionList: collectionList, 
                                        from: CodeMap[detailList?.collectionId || ''],
                                        title: '合成成功',
                                        showNav: 1,
                                    }
                                });
                            }
                        });
                    }else {
                        Toast.show(res.msg);
                    }
                }else {
                    ModalAlert.hide();
                }
               
            },
            actions: [
                { key: 'cancel', text: '取消' },
                { key: 'confirm', text: '确认' }
            ]
        })
    }

    const giveAway = (navigateFlag) => {
        // if (!clickThrottle()){return}
        if (detailList?.transferStatus === 0) {
            if (!!detailList?.targetNftNo && !navigateFlag) { //有关联藏品
                setTargetNftNoModalVisible(true);
            } else {
                zhuanZenPwdJudgeClick();
            }
        } else if (detailList?.transferStatus === 1) {
            getReduceTimeCard();
        } else {
            setModalVisible(true);
        }
    }

    const zhuanZenPwdJudgeClick = async () => { //转赠
        //有无设置过支付密码
        try {
            showLoading();
            const res = await getUserCollectionsSetting({});
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            const {isHasPassword} = data;
            if (isHasPassword) {
                zhuanZenClick();
            } else { //没有设置过密码
                setPwdVisible(true);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const zhuanZenClick = () => {
        let navUrl = `/donationInfo?nftNo=${detailList?.nftNo}&nftImage=${detailList?.nftImage}&targetNftNo=${detailList?.targetNftNo}&gemsCount=${gemsCount}&gemsFee=${gemsFee}`
        if (!!detailList?.imageDetailList && Array.isArray(detailList?.imageDetailList)) {
            navUrl += `&imageDetailList=${JSON.stringify(detailList?.imageDetailList)}`
        }
        if(window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.replace("CustomWeb", {uri: `${window.location.origin}${navUrl}`});
        }else {
            navigate(navUrl);
        }

    }

    const onCloseCardModal = () => {
        setCardVisible(false);
        setChooseArr([]);
    }

    const useCard = async () => {
        try {
            showLoading();
            const params = {
                nftNo: detailList?.nftNo || '',
                collectionId: detailList?.collectionId || '',
                timeReduceCardInfoList: chooseArr,
            };
            const usedRes = await remainTimeCardUse(params);
            hideLoading();
            if (usedRes.code === '000000') {
                Toast.show(usedRes.msg);
                onRefesh && onRefesh();
                onCloseCardModal();
            } else if (usedRes.code === '510016') {
                onCloseCardModal();
                Toast.show(usedRes.msg);
            } else {
                throw usedRes.msg;
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    const onChooseCard = (item) => {
        const arr = [...chooseArr];
        const index = arr.findIndex((i) => i.timeReduceId === item.timeReduceId);
        if (index >= 0) {
            arr.splice(index, 1);
        } else {
            arr.push(item);
        }
        if (Number(item.accumulateDays) === 30) {
            if (arr.length > limitNum(detailList?.remainDays)) {
                Toast.show(`超出剩余转赠天数`);
                return;
            }
        } else {
            const num = arr.reduce((pre, curr) => {
                return pre += curr.reduceDay;
            }, 0);
            if (num > (detailList?.remainDays < (Number(list[0]?.accumulateDays) - Number(usedDays)) ? limitNum(detailList?.remainDays) * list[0]?.reduceDay : (Number(list[0]?.accumulateDays) - Number(usedDays)))) {
                const text = detailList?.remainDays < (Number(list[0]?.accumulateDays) - Number(usedDays)) ? '超出剩余转赠天数' : `单个藏品最多减${item.accumulateDays}天`;
                Toast.show(text);
                return;
            }
        }
        setChooseArr(arr);
    }

    //是否展示孵蛋按钮
    const isShowFD = useMemo(()=>{
        let isShowFD = false;
        if (detailList && detailList?.collectionGroup) {
            isShowFD = true;
        }
    
        let collectionId = parseInt(detailList?.collectionId) || 0;
        if (collectionId === 9) {
            isShowFD = true;
        } else { //已经合成过了
            isShowFD = false;
        }
        return isShowFD;
    },[detailList])

    const BtnBox = {
        0: {style: 'can-donation-bg', text: '转赠'},
        1: {style: 'notdonation-bg', text: `${detailList?.remainDays}天后可转赠`},
        2: {style: 'donation-bg', text: '等待接收'},
        4: {style: 'land-ape', text: ''},
    }

    const modalContentMap = {
        1: {
            title: '未满足持有天数',
            content: `藏品自持有日起，需持有满${detailList?.transferMinimumDays}天后才可以转赠。${getTime(detailList?.collectTime, detailList?.transferMinimumDays)} 之后可以转赠，该藏品不限制转赠次数。`
        },
        2: {title: '等待接收', content: `该藏品已提交转赠流程，等待好友接收。`},
    }
    
    return (
        <div className="detail_footer">
            {
                detailList?.remainDays > 0 &&
                <div className="reduce-time" onClick={() => getReduceTimeCard()}>
                    <img src={reduceImg} alt="" />
                    <span>使用减时卡</span>
                </div>
            }
            {
                spHeChengInfo?.canSynthesize ? 
                <div className="reduce-time"
                    onClick={() => setColCompHomeVisible(!colCompHomeVisible)}
                >
                    <img src={imgSynthesis} alt="" />
                    <span>藏品合成</span>
                </div> 
                : null
            }
            {
                isShowFD ? 
                <div className={classNames('hecheng_btn',{'enable': hcStatus.status === 1})}
                    onClick={() => hcClick()}> 孵化合成</div> 
                : null
            }
            {
                hcStatus.status === 1 && detailList?.collectionId === 207 ?
                <>
                {
                    hcStatus.count >= hcStatus.requireCount?
                    <div className={classNames('hecheng_btn','enable')} 
                        onClick={onKangyangMerge}> 合成</div> :
                    <div className={classNames('hecheng_btn')}> 
                        合成<br/> <span>{`(未满${hcStatus.requireCount ?? '--'}个)`}</span>
                    </div>
                }
                </>
                :null
            }
            
            {
                [78, 79, 80, 81].includes(detailList?.collectionId) ? 
                <div
                    className={classNames('use_beautiful_num', {'re_submit': detailList?.useStatus === 3})}
                    onClick={onUseBeautifulNumBtn}
                >
                    {BeautifulNumBtnText[detailList?.useStatus]}
                </div> 
                : null
            }
            {
                detailList?.transferStatus !== 4 && 
                <div onClick={giveAway}
                    className={classNames('give_away', BtnBox[detailList?.transferStatus]?.style)}
                >
                    {BtnBox[detailList?.transferStatus]?.text}
                </div>
            }
            {/* 减时卡弹框 */}
            <ReduceCard
                visible={cardVisible}
                onClose={onCloseCardModal}
                onChooseCard={onChooseCard}
                reduceDays={reduceDays}
                chooseArr={chooseArr}
                list={list}
                onConfirmUse={()=>setCardModalVisible(true)}
                remainDays={detailList?.remainDays}
            />
            <DonationModal
                subTitle={'与该藏品绑定的共创形象将会一同转赠\n确定进行转赠吗？'}
                visible={!!targetNftNoModalVisible}
                onClose={() => setTargetNftNoModalVisible(false)}
                leftBtnText="取消"
                rightBtnText="确认转赠"
                rightBtnCB={() => {
                    giveAway(true)
                }}
            />
            <DonationModal
                title={modalContentMap[detailList?.transferStatus]?.title}
                content={modalContentMap[detailList?.transferStatus]?.content}
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
            />
            {/* 确认弹框 */}
            <DonationModal
                title="使用减时卡确认"
                content={`确认使用${chooseArr.length}张减时卡？`}
                visible={cardModalVisible}
                onClose={() => setCardModalVisible(false)}
                leftBtnText="取消"
                rightBtnText="使用"
                rightBtnCB={useCard}
            />
            <DonationModal
                title="友情提示"
                content={`为了您的资产安全，请先设置交易密码`}
                visible={pwdVisible}
                onClose={() => setPwdVisible(false)}
                leftBtnText="取消"
                rightBtnText="去设置"
                rightBtnCB={() => {
                    window.JsBridge.push('Web', {uri: `${window.location.origin}/pwdSetting`})
                }}
            />
            {
                colCompHomeVisible ?
                <ColCompoundHome maskVisible={colCompHomeVisible} 
                    setMaskVisible={setColCompHomeVisible} 
                    spHeChengInfo={spHeChengInfo}
                /> : null
            }
        </div>
    )
}

export {
    Footer
}