import {queryDefCode} from "@/api/projectManager_self";
import requests from "@/utils/requests_self";

class Service {
  static activityCode = 'apeWoodenBadge';
  static collectionId = 239;

  /**
   * @description 获取返利藏品初始化数据
   * @param {*} params
   * @returns
   */
  static getInitData = async (params={})=> {
    params = { activityCode: Service.activityCode }
    return await requests.post('/customer/activity/v1/initActivity', JSON.stringify(params));
  }
  /**
   * @description 获取活动规则
   * @param {*} params
   * @returns
   */
  static getActivityRules = async ()=> {
    return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
  }

  /**
   * @description 获取用户信息，是否实名
   * @returns {ID: 存在则实名}
   */
  static getUserCertificateStatus = async () => {
    if (window.JsBridge.hasWebViewBridge()) {
      const res = await window.JsBridge.getDetailCurrentUserInfo()
      return res;
    } else {
      return {};
    }
  }
  /**
   * @description 返还记录
   * @param { * } params
   * @returns
   */
  static getRebateRecord = async (param={})=> {
    const params = {
      activityCode: Service.activityCode,
      ...param,
    }
    return await requests.post(`/customer/activity/v1/collectionRightsRecords?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
  }
  // 获取用户回收的藏品列表
  static getRecoveryActivity = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
  }
  /**
   * @description 获取用户全部可以回购编号
   * @param {*} params
   * @returns
   */
  static selectAllRecoveryNftNoList = async (params={})=> {
    params = { activityCode: Service.activityCode }
    return await requests.post('/customer/recoveryActivity/v2/allRecoveryNftNoList', JSON.stringify(params));
  }

  /**
   * @description 回购
   * @param { * } params
   * @returns
   */
  static buyBackService = async (params={})=> {
    params = {
      activityCode: Service.activityCode,
      ...params,
    }
    return await requests.post('/customer/recoveryActivity/v2/buyBack', JSON.stringify(params));
  }
  /**
   * @description 合成
   * @param { * } params
   * @returns
   */
  static compoundService = async (params = {}) => {
    params = {
      activityCode: Service.activityCode,
      ...params,
    }
    return await requests.post('/customer/recoveryActivity/v2/exec', JSON.stringify(params))
  }
  /**
   * @description 获取木纹徽章合成记录
   * @param { * } params
   * @returns
   */
  static woodBadgeCompoundListService = async (params = {}) => {
    params = {
      activityCode: Service.activityCode,
      ...params,
    }
    return await requests.post(`/customer/recoveryActivity/v2/getRecoveryRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params))
  }
  static getBuyBackList = async (params) => {
    params = {
      activityCode: Service.activityCode,
      ...params,
    }
    return await requests.post(`/customer/recoveryActivity/v2/buyBackRecord`, JSON.stringify(params));
  }
}
export default Service;