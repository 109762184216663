import styles from "./styles.module.scss";
import arrow from "@/assets/img/landfi/select-arrow.png";
import React, {useEffect, useRef, useState} from "react";

const CascaderDropdownSelect = (props) => {
  const { title = '系列', onChange, options = [], defaultValue = 0} = props
  const [showContent, setShowContent] = useState(false);
  const [showSubContent, setShowSubContent] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(options[0]);
  const [subOptions, setSubOptions] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const domRef = useRef();
  const subDomRef = useRef();
  useEffect(() => {
    const findItem = options.find(item => item.value === defaultValue);
    setCurrentSelect(findItem);
  }, [defaultValue])

  useEffect(() => {
    if (defaultValue) return;
    setCurrentSelect(options[0]);
  }, [options])

  const showSelect = () => {
    domRef.current?.scroll(0, 0)
    setShowContent(!showContent);
    showSubContent && setShowSubContent(false)
  }
  const onSelect = (item, index) => {
    setCurrentIndex(index)
    if(item?.subOptions?.length > 0) {
      subDomRef.current?.scroll(0, 0)
      setSubOptions(item.subOptions)
      setShowSubContent(true)
    } else {
      setCurrentSelect(item);
      setShowContent(false);
      setShowSubContent(false)
      onChange && onChange(options[index], {label: '全部', value: 'all'});
    }
  }
  const onSubSelect = (item) => {
    setCurrentSelect(item);
    setShowSubContent(false)
    setShowContent(false);
    onChange && onChange(options[currentIndex], {label: item.label, value: item.value === 'all' ? '' : item.value});
  }
  return (
    <div className={styles.dropdown_select}>
      <div className={styles.dropdown_title}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content_box}>
          <div className={styles.content_top} onClick={showSelect}>
            <span>{currentSelect?.label}</span>
            <img className={showContent ? styles.rotate: ''} src={arrow} alt="" />
          </div>
          <div className={`${styles.dropdown_content} ${showContent ? styles.show: styles.hidden}`} ref={domRef}>
            {
              options.length > 0 && options.map((item, index) => {
                return <div className={styles.dropdown_content_item} key={index} onClick={() => onSelect(item, index)}>{item.label}</div>
              })
            }
          </div>
          <div className={`${styles.sub_dropdown_content} ${showSubContent ? styles.show: styles.hidden}`} ref={subDomRef}>
            {
              subOptions?.length > 0 && subOptions.map((item, index) => {
                return <div className={styles.dropdown_content_item} key={index} onClick={() => onSubSelect(item)}>{item.label}</div>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CascaderDropdownSelect