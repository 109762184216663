import requests from "@/utils/requests_self";
import qs from "query-string";

// guland主页初始化接口
export const gulandInit = async (params = {}) => {
  return await requests.post(
    "/customer/guland/showBlocks",
    JSON.stringify(params)
  );
};

// 我的土地列表
export const myLandsList = async (params = {}) => {
  return await requests.post(
    "/customer/guland/showBlocksDtl",
    JSON.stringify(params)
  );
};

// 工业用地额度
export const getUserEnergy = async (params = {}) => {
  return await requests.post(
    "/customer/energy/v1/getUserEnergy",
    JSON.stringify(params)
  );
};

// 转换信息
export const indLandConvertMsg = async (params = {}) => {
  return await requests.post(
    "/customer/guland/indLandConvertMsg",
    JSON.stringify(params)
  );
};

// 转换信息
export const workingPermissionMsg = async (params = {}) => {
  return await requests.post(
    "/customer/guland/workingPermissionMsg",
    JSON.stringify(params)
  );
};

// 选择放置土地列表
export const placeLandList = async (params = {}) => {
  return await requests.post(
    "/customer/guland/showUnputBlocksDtl",
    JSON.stringify(params)
  );
};

// 确认放置
export const confirmPlaceLand = async (params = {}) => {
  return await requests.post(
    "/customer/guland/putBlock",
    JSON.stringify(params)
  );
};

// 确认领取
export const confirmReceiveLands = async (params = {}) => {
  return await requests.post(
    "/customer/guland/getBlocksConfirm",
    JSON.stringify(params)
  );
};

// 领取土地
export const receiveLands = async (params = {}) => {
  return await requests.post(
    "/customer/guland/getBlocks",
    JSON.stringify(params)
  );
};

// 获取土地升级信息
export const landUpgradeInfo = () =>
  requests.post("/customer/guland/upgradeDtl");

// 获取确认订单信息
// 1：C-B   2：C-A   3：B-A）
export const sureOrderInfo = (params) =>
  requests.post("/customer/guland/landUpgradeInfoDtl", undefined, {
    params,
  });

// 经验等级
export const expLevelInfo = (params) =>
  requests.post("/customer/guland/getLandExpReward", JSON.stringify(params));

// 到期时间
// TODO: 处理方式
export const expireTime = () => requests.post("/customer/guland/unEffectTime");

// 打工猿数据
export const workExpData = async (params = {}) => {
  return await requests.post(
    "/customer/guland/getLazyMonkeyInfo",
    JSON.stringify(params)
  );
};

// 获取经验宝石
export const expGemstores = async (params = {}) => {
  return await requests.post(
    "/customer/guland/myExpAndGemStonesInfo",
    JSON.stringify(params)
  );
};

// 派遣无聊猿打工
export const dispatchWorkApe = async (params = {}) => {
  return await requests.post(
    "/customer/guland/dispatchBoredApes",
    JSON.stringify(params)
  );
};

// 召回无聊猿
export const recallWorkApe = async (params = {}) => {
  return await requests.post(
    "/customer/guland/recallBoredApes",
    JSON.stringify(params)
  );
};

// 购买加速卡列表
export const purchaseAccelerationCardList = async (params = {}) => {
  return await requests.post(
    "/customer/guland/purchasedAccelerationCardsList",
    JSON.stringify(params)
  );
};

// 我的加速卡列表
export const myAccelerationCardList = async (params = {}) => {
  return await requests.post(
    "/customer/guland/myAccelerationCardsList",
    JSON.stringify(params)
  );
};

// 使用加速卡
export const exerciseAccelerationCard = async (params = {}) => {
  return await requests.post(
    "/customer/guland/useAccelerationCards",
    JSON.stringify(params)
  );
};

// 购买记录
export const purchaseRecords = async (params = {}) => {
  return await requests.post(
    "/customer/guland/purchasedRecordsList",
    JSON.stringify(params)
  );
};

// 获取1, "史诗款"，2, "传说款"，3, "隐藏款"，4, "典藏款"
export const myApeCollections = async (params = {}) => {
  return await requests.post(
    "/customer/guland/myBoredApesList",
    JSON.stringify(params)
  );
};

// 加速卡购买信息
export const accelerationPurchaseInfo = async (params = {}) => {
  return await requests.post(
    "/customer/guland/purchasedAccelerationCardsInit",
    JSON.stringify(params)
  );
};

// 每日任务
export const dailyTaskStatus = async (params = {}) => {
  return await requests.post(
    "/customer/guland/dailyTasksStatus",
    JSON.stringify(params)
  );
};

// 宝石领取
export const receiveLevelPrize = async (params = {}) => {
  return await requests.post(
    "/customer/guland/oneClickReward",
    JSON.stringify(params)
  );
};

// 每日任务经验值关联土地
export const dailyTaskToLand = async (params = {}) => {
  return await requests.post(
    "/customer/guland/putExpOnLand",
    JSON.stringify(params)
  );
};

// 空间土地列表
export const spaceLandList = async (params = {}) => {
  return await requests.post(
    "/customer/guland/myPutBlocksList",JSON.stringify(params)
  );
};

// 土地管理费初始化接口
export const initManagementFees = async (params = {}) => {
  return await requests.post(
    "/customer/guland/purchasedManagementFeesInit",
    JSON.stringify(params)
  );
};

// 宝石转赠记录
export const transferRecord = async (params) => {
  return await requests.get(`/customer/guland/transferRecord/${params.type}?${qs.stringify(params)}`);
}

// 宝石转赠
export const gemstoreTransfer = async (params = {}) => {
  return await requests.post(
    "/customer/guland/transfer",
    JSON.stringify(params)
  );
};

// 获取用户宝石数量
export const getGemstoreCount = async () => {
  return await requests.get('/customer/guland/getUserGemstones');
};

// 抽奖信息
export const lotteryInfo = async (params = {}) => {
  return await requests.post(
    "/customer/guland/transfer",
    JSON.stringify(params)
  );
};

// 宝石手续费配置
export const gemstoreFeesConfig = async () => {
  return await requests.get("/customer/guland/getGemstonesFees");
};

// 宝石排行榜
export const gemstoreRanking = async () => {
  return await requests.get("/customer/guland/getGemstonesRank");
};

// c级土地购买信息
export const getPurchaseInfo = async (params = {}) => {
  return await requests.post("/customer/purchase/v1/getPurchaseInfo", JSON.stringify(params));
};

// 获取系统时间
export const getSystemTime = async () => {
  return await requests.get("/customer/base/v1/getTime");
};

// 土地无聊猿租赁列表
export const landApeLeaseList = async (params = {}) => {
  return await requests.post(`/customer/RentalCenter/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
};

// 土地无聊猿租赁详情
export const landApeLeaseDetail = async (params = '') => {
  return await requests.get(`/customer/RentalCenter/v1/ableRentalCollDetail?nftNo=${params}`);
};

// 土地无聊猿租赁
export const landApeLease = async (params = {}) => {
  return await requests.post("/customer/RentalCenter/v1/confirmRentalLease", JSON.stringify(params));
};

// 用户出租租界列表
export const userRentalList = async (params = {}) => {
  return await requests.post(
    `/customer/RentalCenter/v1/rentalList?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, 
    JSON.stringify(params)
  );
};

// 取消藏品出租
export const cancelAbleRental = async (params = {}) => {
  return await requests.post("/customer/RentalCenter/v1/cancelAbleRental", JSON.stringify(params));
};

// 修改藏品出租信息
export const updateAbleRentalInfo = async (params = {}) => {
  return await requests.post("/customer/RentalCenter/v1/updateAbleRentalInfo", JSON.stringify(params));
};

// 空间扩展查询
export const spaceExtendInfo = async (params = {}) => {
  return await requests.get(`/customer/guland/spaceExtendInfo/${params.spaceType}`);
};

// 空间购买初始化接口
export const purchasedLandSpaceInit = async (params = {}) => {
  return await requests.post("/customer/guland/purchasedLandSpaceInit", JSON.stringify(params));
};

// 空间购买初始化接口
export const getMonkeyTypeAndName = async () => {
  return await requests.get("/customer/guland/getMonkeyTypeAndName");
};

// 取消放置土地
export const cancelPlacement = async (params = {}) => {
  return await requests.post("/customer/guland/cancelPlacement", JSON.stringify(params));
};

// 查询藏品所有权
export const getHoleStatus = async (params="") => {
  return await requests.get(`/customer/collection/search/getHoleStatus?nftNo=${params}`);
};

// 获取可出租藏品的天数
export const getAbleRentalPeriod = async () => {
  return await requests.get("/customer/RentalCenter/v1/getAbleRentalPeriod");
};
// 获取出租费比例
export const getFeesRate = async () => {
  return await requests.get("/customer/RentalCenter/v1/getFeesRate");
};
// 发布
export const publish = async (params={}) => {
  return await requests.post(
    "/customer/RentalCenter/v1/publish",JSON.stringify(params)
  );
};
// 获取用户可出租猴子、土地列表
export const ableRentalCollList = async (params={}) => {
  return await requests.post(
    `/customer/RentalCenter/v1/ableRentalCollList?currentPage=${params.currentPage}&pageSize=${params.pageSize}`,JSON.stringify(params)
  );
};
// 宝石明细
export const userGemStonesDetail = async (params) => {
  return await requests.get(
    "/customer/guland/userGemStonesDetail",{ params }
  );
};
// 其他藏品下拉框类别
export const getOtherSerials = async () => {
  return await requests.get("/customer/collections/series/getSeriesList/rentalOther");
};



