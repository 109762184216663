import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Styles from './index.module.scss'
import {Toast} from "antd-mobile";
import Service from "../service";
import NFTModal from "@/components/NFTModal";
import ActivityRules from "../components/ActivityRules";
import CompoundConfirmModal from "@/view/woodGrainBadge/components/CompoundConfirmModal";
import {hideLoading, showLoading} from "@/components/loading";

/**
 * @description 木纹徽章
 * @return {/woodGrainBadge/home}
 */
const Btns = [
  { title: '规则', key: 1 }, { title: '实名', key: 2 },
]
const Home = () => {
  const pageDom = useRef(null);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState({});
  const [goldCount, setGoldCount] = useState(0)
  const [syntheticFlag, setSyntheticFlag] = useState(true)

  useEffect(() => {
    (()=>{
      getData(true);
      onDidFocus();
    })()

  }, []);
  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getData(true)
      onDidFocus();
    });
  }
  const getData = async (loading = false) => {
    try {
      if (loading) showLoading();
      if (loading) {
        const userInfoRes = await Service.getUserCertificateStatus();
        setUserInfo(userInfoRes);
      }
      const res = await Service.getInitData();
      if (loading) hideLoading();
      if (res.code !== '000000') throw res.msg;
      setData(res.data);
      setSyntheticFlag(res.data.syntheticFlag)
      if(res.data?.count !== null && res.data?.count !== undefined && res.data?.requiredCount){
        setGoldCount(~~(Number(res.data.count) / Number(res.data.requiredCount)) || 0)
      }
    } catch (error) {
      console.log('error', error);
      hideLoading();
      Toast.show(error);

    }
  }
  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      navigate(-1);
    }
  }
  const onRightBtnClick = (item) => {
    if (item.key === 1) {
      const ModalInstance = NFTModal.show({
        content: <ActivityRules onClose={() => ModalInstance?.close()} />,
        getContainer: pageDom.current,
      });
    } else if (item.key === 2) {
      window.JsBridge.navigate('Verify');
    }
  }
  // 提示信息
  const tipsMessage = useMemo(() => {
    let msg = undefined;
    const { systemTime, startTime, endTime } = data;
    const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
    const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
    if (!startTime || !endTime) {
      msg = "接口异常";
    } else if (currentT < startT) {
      msg = "活动未开始";
    } else if (currentT > endT) {
      msg = "活动已结束";
    }

    return msg;
  }, [data]);
  const buyHandle = () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    if (!userInfo?.ID) {
      Toast.show("请先实名认证");
      return;
    }
    const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}${uri}`,
      });
    } else {
      navigate(uri);
    }
  }
  const buyBackHandle = async () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    if (!userInfo?.ID) {
      Toast.show("请先实名认证");
      return;
    }
    const uri = '/woodGrainBadge/buyBack'
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
    } else {
      navigate(uri);
    }
  }
  const gotoList = (uri) => {
    if(uri){
      if (window.JsBridge.hasWebViewBridge()) {
        window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
      } else {
        if(uri === '/myCollection?code=18'){
          navigate(uri, { replace: true });
        } else {
          navigate(uri);
        }
      }
    }
  }

  const handleCompound = (type) => {
    if(!syntheticFlag || !(goldCount > 0)){
      return;
    }
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    if (!userInfo?.ID) {
      Toast.show("请先实名认证");
      return;
    }
    const ModalInstance = NFTModal.show({
      content: <CompoundConfirmModal
        onClose={() => ModalInstance?.close()}
        type={type}
        toSeeCollections={() => gotoList('/myCollection?code=18')}
        goldCount={goldCount}
        requiredCount={data.requiredCount}
        successCallBack={() => {
          getData(true)
          onDidFocus();
        }}
      />,
      getContainer: pageDom.current,
    });
  }
  return (
    <div className={Styles.woodGrainBadgeHome} ref={pageDom}>
      <div className={Styles.back} onClick={onBack}></div>
      <div className={Styles.top_bg}>
        <div className={Styles.right_btns}>
          {
            Btns.map((btn, i) => {
              if (btn.key === 2 && userInfo?.ID) return null;
              return (
                <div key={i} className={Styles.rightBtn} onClick={() => onRightBtnClick(btn)}>{btn.title}</div>
              )
            })
          }
        </div>
        <div className={Styles.headIcon}></div>
        <div className={Styles.headText}></div>
        <div className={Styles.amountCard}></div>
        <div className={Styles.shadowImg}></div>
      </div>
      <div className={Styles.contentBox}>
        <div className={Styles.counts}>
          <div className={Styles.label}>当前已持有 <span className={Styles.value}>{data?.count || 0}</span> 枚</div>
        </div>
        <div className={Styles.buttonBox}>
          <div className={Styles.buyButton}>
            <button className={Styles.buy} onClick={buyHandle}>立即购买</button>
          </div>
          <div className={Styles.sellButton}>
            <button className={Styles.buy} onClick={buyBackHandle}>立即回购</button>
          </div>
        </div>
        <div className={Styles.sellListButton} onClick={() => gotoList('/woodGrainBadge/seeBuyBackList')}>回购记录></div>
        <div className={Styles.descContainer}>
          <div className={Styles.descContent}>
            <div className={Styles.title}>合成金色徽章</div>
            <div className={Styles.goldCard}>
              <div className={Styles.text1}>
                1枚金色徽章 <span className={Styles.equalIcon}>=</span> {data?.requiredCount || 100}枚木纹徽章
              </div>
              <div className={Styles.goldIconBox}>
                <div className={Styles.goldImg}></div>
                <div className={Styles.text2}>
                  <div>持有木纹徽章： <span className={Styles.numberText}>{data?.count}</span></div>
                  <div>可合成金色徽章：<span className={Styles.numberText}>{goldCount}</span></div>
                </div>
              </div>
              <div className={Styles.compoundBox}>
                <div className={Styles.compoundBg} style={{filter: (!syntheticFlag || !(goldCount > 0)) ? 'grayscale(100%)' : ''}}>
                  <button disabled={!syntheticFlag || !(goldCount > 0)} className={Styles.compoundBtn} onClick={() => handleCompound('one')}>合成 1 枚</button>
                </div>
                <div className={Styles.compoundBg} style={{filter: (!syntheticFlag || !(goldCount > 0)) ? 'grayscale(100%)' : ''}}>
                  <button disabled={!syntheticFlag || !(goldCount > 0)} className={Styles.compoundBtn} onClick={() => handleCompound('all')}>全部合成</button>
                </div>
              </div>
              <div className={Styles.seeCompoundListBtn} onClick={() => gotoList('/woodGrainBadge/seeCompoundList')}>合成记录></div>
            </div>
            <div className={Styles.title}>金色徽章权益</div>
            <div className={Styles.quanyiText}>
              <div className={Styles.text3}>合成金色徽章每月返还 <span className={Styles.jifenText}>40000</span> 积分</div>
              <div className={Styles.text3}>共计返还 3 个月</div>
              <div className={Styles.text4}>（月底验仓月初发放）</div>
            </div>
            <div className={Styles.totalJifen}>共得 120000积分</div>
            <div className={Styles.seeBackJifenListBtn} onClick={() => gotoList('/purchaseRebate/rebateList')}>权益返还记录></div>
          </div>
        </div>
      </div>
      <div className={Styles.bottomLogo}>
        <img src={require('@/assets/img/kangYangNFT/logo_bottom.png')} alt="" />
      </div>
    </div>
  )
}
export default Home