/**
 * @description 藏品购买返利
 * @return {/purchaseRebate/home}
 */
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Styles from './index.module.scss';
import NFTModal from "@/components/NFTModal";
import ActivityRules from "../components/ActivityRules/index";
import {Toast} from "antd-mobile";
import Service from "../service";
import {hideLoading, showLoading} from "@/components/loading";

const Btns = [
    { title: '规则', key: 1 }, { title: '实名', key: 2 },
]
const Home = () => {
    const pageDom = useRef(null);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [data, setData] = useState({});
    const [activityOver, setActivityOver] = useState(false)

    useEffect(() => {
        (()=>{
            getData(true);
            onDidFocus();
        })()

    }, []);
    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
            getData(true)
            onDidFocus();
        });
    }
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfoRes = await Service.getUserCertificateStatus();
                setUserInfo(userInfoRes);
            }
            const res = await Service.getInitData();
            if (loading) hideLoading();
            if (res.code !== '000000') throw res.msg;
            setData(res.data);
            const { systemTime, startTime, endTime } = res.data;
            const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
            const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
            setActivityOver(currentT > endT)
        } catch (error) {
            console.log('error', error);
            hideLoading();
            Toast.show(error);

        }
    }
    const onBack = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
        } else {
            navigate(-1);
        }
    }
    const toRebateList = () => {
        const uri = '/purchaseRebate/rebateList'
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
        } else {
            navigate(uri);
        }
    }
    const onRightBtnClick = (item = {}) => {
        if (item.key === 1) {
            const ModalInstance = NFTModal.show({
                content: <ActivityRules onClose={() => ModalInstance?.close()} />,
                getContainer: pageDom.current,
            });
        } else if (item.key === 2) {
            window.JsBridge.navigate('Verify');
        }

    }
    // 提示信息
    const tipsMessage = useMemo(() => {
        let msg = undefined;
        const { systemTime, startTime, endTime } = data;
        const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
        const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
        if (!startTime || !endTime) {
            msg = "接口异常";
        } else if (currentT < startT) {
            msg = "活动未开始";
        } else if (currentT > endT) {
            msg = "活动已结束";
        }

        return msg;
    }, [data]);
    const buyHandle = () => {
        if (tipsMessage) {
            Toast.show(tipsMessage);
            return;
        }
        if (!userInfo?.ID) {
            Toast.show("请先实名认证");
            return;
        }
        const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`;
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("CustomWeb", {
                uri: `${window.location.origin}${uri}`,
            });
        } else {
            navigate(uri);
        }
    };
    return (
        <div className={Styles.purchaseRebateHome} ref={pageDom}>
            <div className={Styles.back} onClick={onBack}></div>
            <div className={Styles.top_bg}>
                <div className={Styles.right_btns}>
                    {
                        Btns.map((btn, i) => {
                            if (btn.key === 2 && userInfo?.ID) return null;
                            return (
                                <div key={i} className={Styles.rightBtn} onClick={() => onRightBtnClick(btn)}>{btn.title}</div>
                            )
                        })
                    }
                </div>
                <div className={Styles.headIcon}></div>
                <div className={Styles.headText}></div>
                <div className={Styles.amountCard}></div>
            </div>
            <div className={Styles.contentBox}>
                <div className={Styles.counts}>
                    <div className={Styles.label}>当前剩余: </div>
                    <div className={Styles.value}>{activityOver ? 0 : data?.remainCount}</div>
                </div>
                <div className={Styles.buyButton} onClick={buyHandle}>立即购买</div>
                <div className={Styles.backRecordText} onClick={toRebateList}>返还记录></div>
                <div className={Styles.box}>
                    <div className={Styles.detailCard}>
                        <div className={Styles.text1}>积分收益</div>
                        <div className={Styles.box2}>
                            <div>
                                <span>购买藏品每月返还</span>
                                <span className={Styles.text3}>10000 积分</span>
                            </div>
                            <div>
                                <span>共计返还 12 个月</span>
                                <span className={Styles.text4}>（月底验仓月初发放）</span>
                            </div>
                            <div className={Styles.text5}>共得 120000积分</div>
                        </div>
                        <div className={Styles.addIcon}></div>
                        <div className={Styles.box3}>
                            <div>
                                <div>漫画返积分</div>
                                {/*<div>最多可返<span className={Styles.text6}>50000积分</span></div>*/}
                            </div>
                            <div className={Styles.text7}>
                                <ul>
                                    <li>持有6款和5款漫画藏品的用户，购买返价格50%的等价积分。</li>
                                    <li>持有4款漫画藏品的用户，购买返价格30%的等价积分。</li>
                                    <li>持有3款漫画藏品的用户，购买返价格10%的等价积分。</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.bottomLogo}>
                <img src={require('@/assets/img/kangYangNFT/logo_bottom.png')} alt="" />
            </div>
        </div>
    )
}
export default Home