import NFTHeader from "@/components/NFTHeader";
import React, {useEffect, useRef, useState} from "react";
import ListView from "@/components/AntListView";
import Style from './index.module.scss';
import {hideLoading, showLoading} from "@/components/loading";
import Service from "../service/index";
import {Toast} from "antd-mobile";

const RebateList = () => {
    const [dataSource, setDataSource] = useState();
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(()=>{
        loadData(true)
    },[])

    const loadData = async (isRefesh=false) => {
        const param = {
            pageSize: pageSize.current,
            currentPage: currentPage.current
        }
        try {
            isRefesh && showLoading();
            const res = await Service.getRebateRecord(param);
            isRefesh && hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.list || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isRefesh && hideLoading();
            Toast.show(error);
        }
    }
    const loadMore = async () => {
        currentPage.current += 1;
        await loadData()
    }
    const renderItem = (item)=> {
        return (
            <div className={Style.itemBox}>
                <div className={Style.topBox}>
                    <img className={Style.imgBox} src={item?.nftImage} alt=""/>
                    <div className={Style.nameBox}>
                        <div>{item?.collectionName}</div>
                        <div className={Style.tagBox}>{item?.nftNo}</div>
                    </div>
                </div>
                <div className={Style.divider}></div>
                <div>
                    {item?.rightsDetailList?.length > 0 && item.rightsDetailList.map((subItem, subIndex) => {
                        if(subIndex % 2 === 0){
                            return(
                                <div className={Style.recordBox} key={subIndex}>
                                    <div>
                                        {item.rightsDetailList[subIndex].provideCycle}：
                                        {item.rightsDetailList[subIndex].state === 1 ? <span>待返还</span> : <span style={{ fontWeight: 600 }}>已返还 {item.rightsDetailList[subIndex].point} 积分</span>}
                                    </div>
                                    {subIndex+1 <=item.rightsDetailList.length-1 && (
                                        <div>
                                            {item.rightsDetailList[subIndex+1].provideCycle}：
                                            {item.rightsDetailList[subIndex+1].state === 1 ? <span>待返还</span> : <span style={{ fontWeight: 600 }}>已返还 {item.rightsDetailList[subIndex+1].point}积分</span>}
                                        </div>
                                    )}
                                </div>
                            )
                        }else {
                            return null
                        }
                    })}
                </div>
            </div>
        )
    }
    return(
        <div className={Style.container}>
            <NFTHeader midText={`返还记录`} bgColor={'#FFFFFF'}/>
            <div className={Style.list}>
                <ListView
                    dataSource={dataSource}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoad={true}
                    style={{paddingTop: 50}}
                    onRefresh={()=>{
                        currentPage.current = 1;
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
            </div>
        </div>
    )
}
export default RebateList