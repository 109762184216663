/**
 * @description GR-2405627 盲盒
 * @param {}
 * @returns {/blindBox/home}
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from "antd-mobile";
import PopupModal from "../components/recordModal";
import NFTModal from "@/components/NFTModal";
import { getUrlAllParams } from "@/utils/publicFun";
import { hideLoading, showLoading } from "@/components/loading";
import ActivityRules from "../components/ActivityRules";
// import ShareModal from "../components/shareModal";
import ShareModal from "../components/activityShareModal";
import SuccessModal from "../components/successModal";

import bgImage1 from "@/assets/img/blindbox/fenxiang_bg1.png";
import Service from "../service";
import { getShareInfo,getMyWalletAddress } from "@/api/projectManager_self";

import Styles from "./index.module.scss";
import info1 from "@/assets/img/blindbox/info1.png";
import info2 from "@/assets/img/blindbox/info2.png";
import info3 from "@/assets/img/blindbox/info3.png";
import info4 from "@/assets/img/blindbox/info4.png";
const CardObjcs = [
  {
    id: "1",
    img: info1,
  },
  {
    id: "2",
    img: info2,
  },
];
const CardObjcs2 = [
  {
    id: "3",
    text1: "新用户可得",
    text2: "C级土地",
    img: info3,
  },
  {
    id: "4",
    text1: "老用户可得",
    text2: "10 能量",
    img: info4,
  },
];
const Home = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [dataSource, setDataSource] = useState({});
  const [shareInfo, setShareInfo] = useState({});
  const pageDom = useRef(null);
  // 共创漫画开盲盒参数
  const { search } = useLocation();
  const { from, collectionList, pay_query } = getUrlAllParams(search.slice(1));
  const payQueryData = pay_query
    ? JSON.parse(decodeURIComponent(pay_query))
    : null;
  const list = collectionList
    ? JSON.parse(decodeURIComponent(collectionList))
    : [];
    const [goods, setGoodsInfo] = useState({list,from});

    const getMyWallet = async () => {
      try {
          const res = await getMyWalletAddress();
          if (res.code !== '000000') throw res.msg;
      } catch (error) {
          Toast.show(error);
      }
  }
 
  const getInviteCode = async () => { ///customer/purchase/v1/getShareConfig
    try {
      showLoading();
      const res = await getShareInfo({});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      if (res.data && res.data.code){
          let interverCode = res.data.code;
          setShareInfo(interverCode);
      }
  } catch (error) {
      hideLoading();
      Toast.show(error);
  }
}

  const getData = async (loading = false) => {
    try {
      if (loading) showLoading();
      if (loading) {
        const userInfo = await Service.getUserCertificateStatus();
        setUserInfo(userInfo);
      }
      const res = await Service.getInitData();
      if (loading) hideLoading();
      if (res.code !== "000000") throw res.msg;
      setDataSource(res.data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };
  useEffect(() => {
    getMyWallet()
    if (goods.from === "blindBoxSales" && goods?.list && goods?.list.length) {
      getBlinBoxData();
    }
  }, [goods]);

  const getBlinBoxData = async () => {
    let list = goods.list;
    list = list.map((item) => {
      if (item?.image && item?.image?.includes("?")) {
        let url = item?.url || "";
        item.url = url.split("?")[0];
      }
      return item;
    });
    SuccessModal.open({
      collectionInfo: list,
      leftCB: () => {
        getData(true);
      },
    });
  };

  useEffect(async () => {
    await getData(true);
    await getInviteCode()

  }, []);

  const onBuyClick = () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
        if (res?.result) {
          window.JsBridge.push("CustomWeb", {
            uri: `${window.location.origin}${uri}`,
          });
        }
      });
    } else {
      navigate(uri);
    }
  };
  // 提示信息
  const tipsMessage = useMemo(() => {
    let msg = undefined;
    const { systemTime, startTime, endTime } = dataSource;
    const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
    const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
    if (!startTime || !endTime) {
      msg = "接口异常";
    } else if (currentT < startT) {
      msg = "活动未开始";
    } else if (currentT > endT) {
      msg = "活动已结束";
    }

    return msg;
  }, [dataSource]);

  const onClickRules = () => {
    const ModalInstance = NFTModal.show({
      content: <ActivityRules onClose={() => ModalInstance?.close()} />,
      getContainer: pageDom.current,
    });
  };
  const onClickRecord = () => {
    const uri = `/blindBox/openHistory`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}${uri}`,
      });
    } else {
      navigate(uri);
    }
  };
  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      // window.JsBridge.back();
      window.JsBridgeNew.navigatePopToTop()
    } else {
      window.history.go(-1);
    }
  };

  const queryInviteRecord = () => {
    const { currentTime, startTime } = dataSource;
    if (currentTime < startTime) return;
    PopupModal.open(Service.activityCode);
  };

  const onInvite = () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    const param = {
      ic: shareInfo,
      ac: Service?.activityCode,
    };
    console.log(param);
    const shareUrl = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registers/${btoa(
      JSON.stringify(param)
    )}`;
    ShareModal.open({
      // className: "activityShareCode",
      shareUrl,
      qrcodeConfig: { size: 54 },
      children: <img className={Styles.shareInvite} src={bgImage1} alt="bg" />,
    });
  };

  return (
    <div className={Styles.outer}>
      <div className={Styles["home-top"]}>
        <div className={Styles.back} onClick={onBack}></div>
        <div className={Styles.right_btns}>
          {!userInfo?.ID ? (
            <div
              className={Styles.activity_rules}
              onClick={() => {
                window.JsBridge.navigate("Verify");
              }}
            >
              实 名
            </div>
          ) : null}

          <div
            className={Styles.real_name}
            onClick={() => {
              onClickRules();
            }}
          >
            规 则
          </div>
        </div>
        <div className={Styles.wraper}>
          <div className={Styles.buy_btn}>
            <button
              className={Styles.buy}
              onClick={() => {
                onBuyClick();
              }}
            >
              立即购买
            </button>
          </div>
        </div>
      </div>
      <div className={Styles["home-bottom"]}>
        <div
          className={Styles.record}
          onClick={() => {
            onClickRecord();
          }}
        >{`开盒记录>`}</div>
        <div className={Styles.info}>
          {CardObjcs.map((item) => {
            return (
              <div className={Styles.infoItem} key={item?.id}>
                <img src={item.img} alt="" />
              </div>
            );
          })}
        </div>
        <div className={Styles.info2}>
          <div className={Styles.title}></div>
          <div className={Styles.infoBox}>
            {CardObjcs2.map((item) => {
              return (
                <div className={Styles.infoOut} key={item?.id}>
                  <div className={Styles.infoItem}>
                    <img src={item.img} alt="" />
                  </div>
                  <div className={Styles.text1}>{item?.text1}</div>
                  <div className={Styles.text2}>{item?.text2}</div>
                </div>
              );
            })}
          </div>
          <div className={Styles.btn}>
            <button
              className={Styles.new}
              onClick={() => {
                onInvite();
              }}
            >
              立即邀新
            </button>
            <div
              className={Styles.record}
              onClick={() => {
                queryInviteRecord();
              }}
            >{`邀新记录>`}</div>
          </div>
        </div>
        <div className={Styles.bottom_logo}>
          <img
            src={require("@/assets/img/kangYangNFT/logo_bottom.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
