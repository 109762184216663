import Home from "./home";
import BuyBack from "./buyBack";
import SeeBuyBackList from "./seeBuyBackList";
import SeeCompoundList from "./seeCompoundList";

export default {
  Home,
  BuyBack,
  SeeBuyBackList,
  SeeCompoundList,
}