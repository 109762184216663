import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import DropdownSelect from "./components/dropdownSelect";
import {LevelOptions, getLeaseTerm, CateOptions, getApeCate, getOtherSelect} from "../utils";
import LeaseItem from "./components/leaseItem";
import { Toast } from 'antd-mobile';
import ListView from "@/components/AntListView";
import MyModal from "../jewelLand/modal";
import { hideLoading, showLoading } from "@/components/loading";
import LeaseOutDetail from "./components/leaseOutDetail";
import { userRentalList } from '@/api/guland';
import CascaderDropdownSelect from "@/view/landGame/leaseCenter/components/cascaderDropdownSelect";

const MyLease = () => {
  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [categorary, setCategorary] = useState(0)
  const [landLevel, setLandLevel] = useState(0)
  const [leaseTerm, setLeaseTerm] = useState(0)
  const [daysOptions, setDaysOptions] = useState([])
  const [apeCate, setApeCate] = useState([])
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const [seriesId, setSeriesId] = useState('all')
  const [collectionIds, setCollectionIds] = useState('all')
  const [otherOptions, setOtherOptions] = useState([])

  const getOtherOptions = async () => {
    try {
      const res = await getOtherSelect()
      setOtherOptions(res)
    }catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getDaysOptions();
    getApeCateOptions();
    getOtherOptions();
  }, [])

  useEffect(() => {
    getList(true);
  }, [categorary, leaseTerm, landLevel, seriesId, collectionIds])

  const getList = async (isRefresh, loading = true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        blockLevel: landLevel === 0 ? '': landLevel,
        rentalPeriod: leaseTerm === 0 ? '': leaseTerm,
        monkeyType: landLevel === 0 ? '': landLevel,
        collectionType: categorary,
        rentalType: 2,
        seriesId: categorary === 3 ? seriesId : null,
        collectionIds: categorary === 3 ? collectionIds : null,
      }
      const res = await userRentalList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setList(res?.data?.list || [])
      } else {
        setList([...list, ...res?.data?.list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.list?.length >= pageSize.current)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const getDaysOptions = async () => {
    try {
      const res = await getLeaseTerm();
      const newList = res?.filter(item => item.label !== "永久")
      setDaysOptions(newList);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };

  const getApeCateOptions = async () => {
    try {
      const res = await getApeCate();
      setApeCate(res)
    } catch (error) {
      Toast.show(error);
    }
  };

  const onItemClick = (item) => {
    const ModalInstance = MyModal.show({
      content: <LeaseOutDetail 
        onClose={() => ModalInstance?.close()} 
        showInfo={true}
        showTime={true}
        infoData={item}
      />,
    })
  }

  const onCateClick = (item) => {
    setCategorary(item.value)
    setLandLevel(0)
    setLeaseTerm(0)
  }

  return <div className={styles.land_lease}>
    <div className={styles.select_box}>
      <DropdownSelect 
        title="分类" 
        defaultValue={categorary} 
        options={CateOptions} 
        onChange={onCateClick}
      />
      {categorary !== 0 && categorary !== 3 ? <DropdownSelect
        title="等级" 
        options={categorary === 1 ? apeCate: LevelOptions} 
        onChange={(item) => setLandLevel(item.value)}
        defaultValue={landLevel}
      />: null}
      {categorary === 3 ? <CascaderDropdownSelect
        title="系列"
        options={otherOptions}
        onChange={(item, subItem) => {
          if(item) {
            setSeriesId(item?.value)
            if(subItem){
              setCollectionIds(subItem.value)
            }
          }
        }}/>: null}
      <DropdownSelect 
        title="租期" 
        options={daysOptions} 
        onChange={(item) => setLeaseTerm(item.value)}
        defaultValue={leaseTerm}
      />
    </div>
    <div className={styles.land_lease_list}>
      {
        <ListView
          dataSource={list}
          hasMore={hasMore}
          style={{paddingTop: 5}}
          renderItem={(item) => <LeaseItem 
            item={item} 
            showTag={true} 
            showTime={true} 
            onClick={() => onItemClick(item)}
            isLand={item.type === 2}
          />}
          onEndReached={async () => {
            await getList(false, false);
          }}
          isLoad={isLoad}
          customStyle={{imgStyle: {width: 150, height: 150}}}
        />
      }
    </div>
  </div>
}

export default MyLease;