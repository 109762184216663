import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import styles from "./style.module.scss";
import { Toast } from "antd-mobile";
import ListView from "@/components/AntListView";
import appService from "@/utils/appService";
import _ from "lodash";
import classNames from "classnames";
const GemStoreList = forwardRef(({ onChange, container }, ref) => {
  const [currentSelect, setCurrentSelect] = useState([]);
  const [recordsData, setRecordsData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(50);

  useEffect(() => {
    getRecordsList();
  }, []);

  const getRecordsList = async (isRefresh = true, loading = true) => {
      if (isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const res = await appService("600903", { activityCode: "indLand" }, false);
      if (res.status > 0) {
        if (currentPage.current === 1) {
          setRecordsData(res?.data?.list || []);
        } else {
          setRecordsData([...recordsData, ...res?.data?.list]);
        }
        isRefresh && setIsLoad(true);
        setHasMore(res?.data?.list.length >= pageSize.current);
      }
  };

  const onSelect = (item) => {
    let arr = _.cloneDeep(currentSelect);
    let newArr = _.cloneDeep(currentSelect);
    const index = _.findIndex(arr, function (o) {
      return o?.id == item?.id;
    });

    if (index !== -1) {
      newArr = _.remove(arr, function (n) {
        return n?.id !== item?.id;
      });
    } else {
      newArr.push(item);
    }
    if (newArr?.length > 1) {
      Toast.show("最多选择1个");
      return;
    }
    setCurrentSelect(newArr);
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      currentPage.current = 1;
      setCurrentSelect([]);
      onChange && onChange([]);
      setHasMore(false);
      setIsLoad(false);
      getRecordsList(true);
    },
  }));
  const RecordsItem = (item) => {
    let isActive = false;
    try {
      currentSelect.forEach((_item) => {
        if (_item?.id === item?.id) {
          isActive = true;
          throw "";
        }
      });
    } catch (error) {}
    return (
      <div
        key={item?.nftNo}
        className={classNames(styles.gem_store_list_item, {
          [styles.actived]: isActive,
        })}
        onClick={() => {
          onSelect(item);
        }}
      >
        <div className={styles.item_top}>
          <img src={item.imageUrl} alt="" />
        </div>
        <div className={styles.item_bottom}>
          <div className={styles.item_gem}>{item?.gems || '0'}</div>
          <div className={styles.item_energy}>{item?.energy || '0'}</div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={styles.gem_store_list_out}
      style={
        recordsData.length == 0
          ? { overflowY: "hidden" }
          : { overflowY: "scroll" }
      }
    >
      <ListView
        dataSource={recordsData}
        hasMore={hasMore}
        style={{ paddingTop: 5 }}
        renderItem={RecordsItem}
        onEndReached={async () => {
          await getRecordsList(false, false);
        }}
        isLoad={isLoad}
      />

      <div className={styles.gem_store_opt}>
        <div
          className={classNames(styles.gem_store_btn, {
            [styles.activedBtn]: currentSelect?.length > 0,
          })}
          onClick={() => {
            if (currentSelect?.length > 0) {
              onChange && onChange(currentSelect);
            } else {
              Toast.show("请选择兑换商品");
              return;
            }
          }}
        >
          立即兑换
        </div>
      </div>
    </div>
  );
});

export default GemStoreList;
