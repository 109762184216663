/**
 * @description GR-2405627 ape world 配件盲盒相关接口
*/
import requests from '@/utils/requests_self';
import { queryDefCode, getActivityTime, getOpenRecord,industrialApeActivity} from "@/api/projectManager_self";
import collection from '@/assets/img/variationApe/collection-temp.png';
import moment from 'moment';

const mock = false;

class Service {
    // 活动码
    static activityCode = 'blindBoxSales';

    static collectionId = 237;

    /**
     * @description 获取配件盲盒初始化数据
     * @param {*} params 
     * @returns
    */
    static getTimeData = async (params = {}) => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
        // params = { activityCode: Service.activityCode, clientId: userInfo?.clientId, ...params };
        params = { activityCode: Service.activityCode }
        return await getActivityTime(params);

    }
    static getInitData = async (params = {}) => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
        // params = { activityCode: Service.activityCode, clientId: userInfo?.clientId, ...params };
        params = { activityCode: Service.activityCode }
        return await industrialApeActivity(params);

    }
    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async () => {
        return await queryDefCode({ codeType: 'activityRule', code: Service.activityCode });
    }

    /**
     * @description 兑换记录
     * @param { * } params
     * @returns
    */
    static getRecord = async (params = {}) => {
        const param = {
            activityCode: Service.activityCode,
            ...params,
        }
        if (mock) {
            const mockData = {
                code: '000000',
                msg: 'success',
                data: {
                    recoveryList: [
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                    ]
                }
            }

            return Promise.resolve(mockData);
        }
        return await getOpenRecord(param);
    }

    // 获取用户信息，是否实名

    /**
     * @description 获取用户信息，是否实名
     * @returns {ID: 存在则实名}
     */
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }
}

export default Service;