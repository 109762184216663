import Style from './index.module.scss'
import NFTHeader from "@/components/NFTHeader";
import React, {useEffect, useRef, useState} from "react";
import ListView from "@/components/AntListView";
import dayjs from "dayjs";
import {hideLoading, showLoading} from "@/components/loading";
import Service from "@/view/woodGrainBadge/service";
import {Toast} from "antd-mobile";
const SeeBuyBackList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  const loadData = async (isRefesh=false) => {
    const param = {
      pageSize: pageSize.current,
      currentPage: currentPage.current,
    }
    try {
      isRefesh && showLoading();
      const res = await Service.getBuyBackList(param);
      isRefesh && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res.data.records || [];
      if(currentPage.current === 1) {
        setDataSource(newList);
        setHasMore(res.data.total > newList.length);
      } else {
        setDataSource([...dataSource, ...newList]);
        setHasMore(res.data.total >[...dataSource, ...newList].length)
      }
    } catch (error) {
      isRefesh && hideLoading();
      Toast.show(error);
    }
  }
  const loadMore = async () => {
    currentPage.current += 1;
    await loadData()
  }
  useEffect(()=>{
    loadData(true)
  },[])
  const renderItem = (item)=> {
    return (
      <div className={Style.itemBox}>
        <div className={Style.topBox}>
          <img className={Style.imgBox} src={item?.nftImage} alt=""/>
          <div className={Style.nameBox}>
            <div>{item?.collectionName}</div>
            <div className={Style.tagBox}>{item?.nftNo}</div>
          </div>
        </div>
        <div className={Style.divider}></div>
        <div className={Style.detailBox}>
          <div>回购时间：{item?.backTime ? dayjs(item.backTime).format('YYYY.MM.DD HH:mm:ss') : ''}</div>
          <div>获得积分：{item?.point || 1000}</div>
          <div>是否暴击：<span className={item.isCrit ? Style.redText : ''}>{item.isCrit ? '是' : '否'}</span></div>
        </div>
      </div>
    )
  }
  return (
    <div className={Style.container}>
      <NFTHeader midText="回购记录" bgColor={'#FFFFFF'}/>
      <div className={Style.list}>
        <ListView
          dataSource={dataSource}
          renderItem={renderItem}
          hasMore={hasMore}
          isLoad={true}
          style={{paddingTop: 50}}
          onRefresh={()=>{
            currentPage.current = 1;
            loadData()
          }}
          onEndReached={loadMore}
        />
      </div>
    </div>
  )
}
export default SeeBuyBackList