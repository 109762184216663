import React from "react";
import { Toast } from "antd-mobile";
import { publish } from "@/api/guland";
import styles from "./styles.module.scss";
import close from "@/assets/img/landfi/icon_del.png";
import { hideLoading, showLoading } from "@/components/loading";
const TipsModal = ({
  onClose,
  selectData = [],
  selectList = {},
  rentalFee,
  rentalPeriod,
  type,
  earning,
}) => {
  const confirmHandle = async () => {
    try {
      showLoading()
      const nftNoList = selectData.map(item => item?.nftNo)
      const params = {
        rentalPeriod:type === 3 ? -1 : Number(rentalPeriod.value),
        rentalFee:Number(rentalFee),
        nftNoList,
        rentalType: type,
      };
      const res = await publish(params);
      hideLoading()
      if (res.code !== "000000") throw res.msg;
      if (res.code === "000000") {
        Toast.show({
          content: "发布成功",
          maskClickable: false,
          afterClose: () => {
            onClose(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };
  return (
    <div className={styles.modal_box}>
      <div className={styles.modal_header}>
        <div className={styles.modal_title}>确认发布</div>
        <img
          className={styles.close}
          src={close}
          alt=""
          onClick={() => onClose()}
        />
      </div>
      <div className={styles.modal_content}>
        {type === 3 ? (
          <div className={styles.info}>
            <p>发布后预计收入 {earning || 0} 宝石</p>
            <p>确定发布永久租赁？</p>
            <p>永久租赁后藏品将被转移</p>
          </div>
        ) : (
          <div className={styles.info}>
            {`确认发布${Object.entries(selectList).map((item) => {
              const name = item[0];
              const count = item[1]?.length || 0;
              return `${count}个${name}${type === 1 ? "无聊猿" : "土地"}`;
            })}租赁？`}
          </div>
        )}
      </div>

      <div className={styles.modal_opt}>
        <div
          className={styles.modal_opt_cancel}
          onClick={() => {
            onClose();
          }}
        ></div>
        <div
          className={styles.modal_opt_confirm}
          onClick={() => {
            confirmHandle();
          }}
        ></div>
      </div>
    </div>
  );
};

export default TipsModal;
