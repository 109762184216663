/**
 * @description 统一发售流程
*/
import SaleDetail from './SaleDetail';
import PayPage from './PayPage';
import PaySuccess from './PaySuccess';
import DefaultPay from './DefaultPay/index';

export default {
    SaleDetail,
    PayPage,
    PaySuccess,
    DefaultPay,
}