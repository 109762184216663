/**
 * @description 兑换记录
 * @param {}
 * @returns { /apeWorld/partsBlindbox/exchangeHistory }
 */
import React, { useEffect, useState, useRef } from "react";
import { Toast } from "antd-mobile";
import NFTHeader from "@/components/NFTHeader";
import { hideLoading, showLoading } from "@/components/loading";
import ListView from "@/components/AntListView";
import Service from "../service";
import Style from "./index.module.scss";

const ExchangeHistory = () => {
  const [dataSource, setDataSource] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    loadData(true);
  }, []);

  const loadData = async (isRefesh = false) => {
    const param = {
      pageSize: pageSize.current,
      page: currentPage.current,
      apeOrderType: 18,
    };
    try {
      isRefesh && showLoading();
      const res = await Service.getRecord(param);
      isRefesh && hideLoading();
      if (res.code !== "000000") throw res.msg;
      const newList = res.data.list || [];
      if (currentPage.current === 1) {
        setDataSource(newList);
      } else {
        setDataSource([...dataSource, ...newList]);
      }
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      isRefesh && hideLoading();
      Toast.show(error);
    }
  };

  const loadMore = async () => {
    currentPage.current += 1;
    await loadData();
  };

  const renderItem = (item) => {
    const acquiredGoods = item?.acquiredGoods || {}
   
    return (
      <div className={Style.record_item}>
        <div className={Style.top}>
          <img src={require("@/assets/img/blindbox/box-img.png")} alt="" />
          <div>
            <span>{item.collectionName ?? "盲盒"}</span>
            <p>{`${item.num ? `x${item?.num}` : "-"}`}</p>
          </div>
        </div>
        <div className={Style.bottom}>
          <div className={Style.alignTop}>
            <p>获得商品</p>
            <div className={Style.bottom}>
              {Object.getOwnPropertyNames(acquiredGoods)?.map((item) => {
                return <span key={item}>{`${item} x${acquiredGoods[item]}`}</span>;
              })}
            </div>
          </div>
          <div>
            <p>购买时间</p>
            <span>{item.orderTime}</span>
          </div>
          <div>
            <p>订单编号</p>
            <span>{item.outTradeNo}</span>
          </div>
          <div>
            <p>实付款</p>
            <span>¥{item.price?? "-"}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={Style.ExchangeHistory}>
      <NFTHeader midText={`开盒记录`} />
      <div className={Style.historyList}>
        <ListView
          dataSource={dataSource}
          renderItem={renderItem}
          hasMore={hasMore}
          isLoad={true}
          style={{ paddingTop: 50 }}
          onRefresh={() => {
            currentPage.current = 1;
            loadData();
          }}
          onEndReached={loadMore}
        />
      </div>
    </div>
  );
};

export default ExchangeHistory;
