import React, {useEffect, useRef, useState} from "react";
import Style from "./index.module.scss";
import dayjs from "dayjs";
import NFTHeader from "@/components/NFTHeader";
import ListView from "@/components/AntListView";
import Service from "@/view/woodGrainBadge/service";
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import goldIcon from "@/assets/img/woodGrainBadge/apeGold.png"

const SeeCompoundList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  const loadData = async (isRefesh=false) => {
    const param = {
      pageSize: pageSize.current,
      currentPage: currentPage.current,
    }
    try {
      isRefesh && showLoading();
      const res = await Service.woodBadgeCompoundListService(param);
      isRefesh && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res.data.record || [];
      if(currentPage.current === 1) {
        setDataSource(newList);
        setHasMore(res.data.totalCount > newList.length);
      } else {
        setDataSource([...dataSource, ...newList]);
        setHasMore(res.data.totalCount >[...dataSource, ...newList].length)
      }
    } catch (error) {
      isRefesh && hideLoading();
      Toast.show(error);
    }
  }
  const loadMore = async () => {
    currentPage.current += 1;
    await loadData()
  }
  useEffect(()=>{
    loadData(true)
  },[])
  const renderItem = (item)=> {
    return (
      <div className={Style.itemBox}>
        <div className={Style.topBox}>
          <img className={Style.imgBox} src={goldIcon} alt=""/>
          <div className={Style.nameBox}>
            <div>{item?.prizeName ? item.prizeName.split(',')[0] : '无聊猿金色徽章'}</div>
            <div className={Style.tagBox}>x{item?.postCount}</div>
          </div>
        </div>
        <div className={Style.divider}></div>
        <div className={Style.detailBox}>
          <div>
            <div className={Style.leftText}>合成使用材料</div>
            <div className={Style.rightText}>{item?.preCount} 枚 {item?.recoveryList?.length > 0 ? item.recoveryList[0]?.name : '木纹徽章'}</div>
          </div>
          <div>
            <div className={Style.leftText}>合成时间</div>
            <div className={Style.rightText}>{item?.recoveryTime ? dayjs(item.recoveryTime).format('YYYY.MM.DD HH:mm:ss') : ''}</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={Style.container}>
      <NFTHeader midText="合成记录" bgColor={'#FFFFFF'}/>
      <div className={Style.list}>
        <ListView
          dataSource={dataSource}
          renderItem={renderItem}
          hasMore={hasMore}
          isLoad={true}
          style={{paddingTop: 50}}
          onRefresh={()=>{
            currentPage.current = 1;
            loadData()
          }}
          onEndReached={loadMore}
        />
      </div>
    </div>
  )
}
export default SeeCompoundList