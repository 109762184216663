import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ModalView from "../modalView";
import gem from '@/assets/img/landfi/lease-gem.png';
import arrow from '@/assets/img/landfi/select-arrow.png';
import { Input, Toast } from 'antd-mobile';
import CountDown from "../countDown";
import { getAbleRentalPeriod, updateAbleRentalInfo, getSystemTime, getGemstoreCount } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";

const LeaseOutDetail = (props) => {
  const [daysOptions, setDaysOptions] = useState([]);
  const [leaseTerm, setLeaseTerm] = useState({});
  const [leasePrice, setLeasePrice] = useState('');
  const [sysTime, setSysTime] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const { onClose, onCancel, onEdit, showInfo = false, showTime = false, infoData = {}} = props
  const { 
    nftNo, 
    rentalFee, 
    rentalPeriod, 
    reqNo, 
    rentalTime, 
    raddress, 
    collectionName, 
    rentalExpireTime, 
    currentTime,
    nftImage,
  } = infoData;

  useEffect(() => {
    const defaultItem = daysOptions.find(item => item.value === rentalPeriod);
    setLeasePrice(rentalFee);
    setLeaseTerm(defaultItem)
    setCurrentValue(defaultItem?.value || 1);
  }, [infoData, daysOptions])

  useEffect(() => {
    getDaysOptions();
    getSysTime();
  }, [])

  const getSysTime = async () => {
    const res = await getSystemTime();
    setSysTime(res?.data || '');
  }

  const onCancelLease = () => {
    onCancel && onCancel();
  }

  const onEditLeaseInfo = () => {
    if (isEdit) {
      onEditInfo();
      return
    }
    setIsEdit(true);
  }

  const getDaysOptions = async () => {
    try {
      const res = await getAbleRentalPeriod();
      if (res.code !== "000000") throw res.msg;
      const arr = res.data?.split(",");
      const listTemp = [];
      arr.forEach((item) => {
        if (item === '-1') {
          listTemp.push({ label: "永久", value: Number(item) });
        } else {
          listTemp.push({ label: item + "天", value: Number(item) });
        }
      });
      setDaysOptions(listTemp);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };

  const onEditInfo = async () => {
    if (leasePrice < 5 || leasePrice > 100000) return Toast.show("租赁费用5-100000");
    try {
      showLoading();
      const res = await updateAbleRentalInfo({
        nftNo: nftNo,
        rentalPeriod: leaseTerm.value,
        rentalFee: leasePrice
      })
      hideLoading()
      if (res.code !== "000000") throw res.msg;
      onClose();
      Toast.show("修改成功");
      onEdit && onEdit();
    } catch (error) {
      Toast.show(error);
    }
  }

  return <ModalView title="详细信息" onClose={onClose}>
    <div className={styles.lease_out_detail}>
      <div className={styles.lease_land_top}>
        <div className={styles.land_img}><img src={nftImage} alt="" /></div>
        <div className={styles.land_info}>
          <div className={styles.info_title}>{collectionName}</div>
          <div className={`${styles.common_item} ${styles.lease_no}`}>编号：{nftNo}</div>
          {!isEdit ? <div>
            <div className={`${styles.common_item} ${styles.lease_term}`}>
              <span>租期：{rentalPeriod === -1 ? "永久" : rentalPeriod + "天"}</span>
              {showTime ? <span>剩余时间：<CountDown sysTime={sysTime || currentTime} endTime={rentalExpireTime}/></span>: null}
            </div>
            <div className={styles.lease_price}>
              <img src={gem} alt="" />
              <span>{rentalFee}</span>
            </div>
          </div>: <div>
            <div className={styles.lease_term_edit}>
              <div className={styles.common_item}>租期：</div>
              {infoData?.type === 3 && infoData?.rentalPeriod === -1 ?
                <div className={styles.time_forever} >永久租赁</div>
                : <DropdownSelect options={daysOptions} onChange={(value) => setLeaseTerm(value)} defaultValue={currentValue}/>}
            </div>
            <div className={styles.lease_price_edit}>
              <img src={gem} alt="" />
              <Input 
                className={styles.input_price}
                value={leasePrice}
                onChange={(e) => {
                  if (/^\+?[1-9][0-9]*$/.test(e)) {
                    setLeasePrice(e)
                  } else {
                    setLeasePrice('')
                  }
                }}
              />
            </div>
          </div>}
        </div>
      </div>
      {!showInfo ? <div className={styles.lease_land_bottom}>
        <div className={styles.btn_box}>
          <div className={styles.cancel} onClick={onCancelLease}><div className={styles.inner}>取消租赁</div></div>
          <div className={styles.confirm} onClick={onEditLeaseInfo}>
            <div className={styles.inner}>{isEdit ? '确认修改' : '修改租赁信息'}</div>
          </div>
        </div>
      </div>:<div className={styles.lease_info}>
        <div className={styles.lease_info_item}>
          <span>租赁单号：</span>
          <span>{reqNo}</span>
        </div>
        <div className={styles.lease_info_item}>
          <span>承租人：</span>
          <span>{raddress}</span>
        </div>
        <div className={styles.lease_info_item}>
          <span>承租时间：</span>
          <span>{rentalTime}</span>
        </div>
      </div>}
    </div>
  </ModalView>
}

const DropdownSelect = ({title, onChange, options = [], defaultValue = 0}) => {
  const [showContent, setShowContent] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(options[0]);

  useEffect(() => {
    if (defaultValue) return
    setCurrentSelect(options[0]);
  }, [options])

  useEffect(() => {
    const findItem = options.find(item => item.value === defaultValue);
    setCurrentSelect(findItem);
  }, [defaultValue])


  const showSelect = () => {
    setShowContent(!showContent);
  }

  const onSelect = (item) => {
    setCurrentSelect(item);
    setShowContent(false);
    onChange && onChange(item);
  }

  return <div className={styles.dropdown_select}>
    <div className={styles.dropdown_title}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content_box}>
        <div className={styles.content_top} onClick={showSelect}>
          <span>{currentSelect?.label}</span>
          <img className={showContent ? styles.rotate: ''} src={arrow} alt="" />
        </div>
        <div className={`${styles.dropdown_content} ${showContent ? styles.show: styles.hidden}`}>
          {
            options.length > 0 && options.map((item, index) => {
              return <div className={styles.dropdown_content_item} key={index} onClick={() => onSelect(item)}>{item.label}</div>
            })
          }
        </div>
      </div>
    </div>
  </div>
}

export default LeaseOutDetail;