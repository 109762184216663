import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import close from '@/assets/img/icon/close.png';
import {hideLoading, showLoading} from "@/components/loading";
import { Toast, Popup } from 'antd-mobile';
import ListView from '@/components/AntListView';
import { getInviteRecord } from '@/api/projectManager_self';

const mockData = {
  code: '000000',
  msg: 'success',
  data: {
    list: [
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请购买成功',
        loginName: '13236510099',
        reason: '',
      },
      {
        createTime: '2023-03-20 09:00:00',
        inviteStatus: '邀请失败',
        loginName: '13236510099',
        reason: '被邀请人设别重复登录',
      },
    ]
  }
}

const mockFetch = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mockData);
    }, 2000);
  })
}

const PopupModal = ({onClose, activityCode}) => {
  const [hasMore, setHasMore] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [visible, setVisible] = useState(true);
  const currentPage = useRef(1);
  const pageSize = useRef(15);

  useEffect(() => {
    visible && getRecordList();
    console.log(activityCode)
  }, [visible]);

  const getRecordList = async (isRefresh=true, isLoading=true) => {
    try {
      currentPage.current = isRefresh ? 1: currentPage.current + 1;
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        activityCode: activityCode
      }
      isLoading && showLoading();
      const res = await getInviteRecord(params);
      // const res = await mockFetch();
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      if(currentPage.current > 1) {
        setRecordList([...recordList, ...res.data.list]);
      } else {
        setRecordList(res.data.list);
      }
      setIsLoad(true);
      setHasMore(res.data.list.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  const cancel = () => {
    setVisible(false);
    onClose && onClose();
  }

  const RenderItem = ({loginName, createTime, inviteStatus, reason}) => {
    const loginNameTemp = loginName.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    return <div className="variation-item">
      <div className="item-left">
        <span>{loginNameTemp}</span>
        <span>{createTime}</span>
      </div>
      <div className="item-right">
        <span>{inviteStatus}</span>
        {reason ? <span>{reason}</span>: null}
      </div>
    </div>
  }

  return <Popup
    visible={visible}
    onMaskClick={() => cancel()}
    bodyStyle={{ 
      borderRadius: "9px 9px 0 0",  
      background: 'linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%)',
      backdropFilter: 'blur(11px)',
      boxShadow: 'inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42)'
    }}
  >
    <div className='invite-popup-container'>
      <div className="invite-title">
        <span>邀新记录</span>
        <img onClick={() => cancel()} src={close} alt="" />
      </div>
      <div className="variation-invite-list">
        <ListView 
          dataSource={recordList}
          renderItem={RenderItem}
          hasMore={hasMore}
          onRefresh={() => getRecordList(true, false)}
          onEndReached={() => getRecordList(false, false)}
          style={{paddingTop: 60}}
          isLoad={isLoad}
        />
      </div>
    </div>
  </Popup>
}

PopupModal.open = (activityCode) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<PopupModal onClose={close} activityCode={activityCode}/>, div);
}

export default PopupModal;