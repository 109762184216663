/**
 * @description 我的数字藏品
 * @param { initalTab: 一级tab(0,1), code: 二级tab对应的标识例如: '09', subCode: 三级tab标识} 根据参数跳转定位到某个tab下
 * @returns {route: /myCollection }
*/
import React, {useEffect, useState, useRef} from "react"
import {
    getCollection,
    getCollectionTabs,
    getCollectionCount,
    getUserInfo,
    getMyWalletAddress, queryAdvertisementByType
} from "@/api/projectManager_self";
import {Toast, Tabs} from 'antd-mobile';
import ListView from "@/components/AntListView/index";
import {showLoading, hideLoading} from '@/components/loading';
import NFTHeader from '@/components/NFTHeader';
import "./index.scss"
import {useNavigate, useLocation} from "react-router-dom";
import {getUrlAllParams, isEmptyString} from "@/utils/publicFun"
import icon_dindan from '@/assets/img/icon_dindan.png';
import icon_chaxun from '@/assets/img/icon_chaxun.png';
import icon_shequ from '@/assets/img/icon_shequ.png';
import icon_zhuanzen from '@/assets/img/icon_zhuanzen.png';
import icon_fuzhi from '@/assets/img/icon_fuzhi.png';
import icon_search from '@/assets/img/icon_search.png';
import icon_shezhi from '@/assets/img/icon_shezhi.png';
import icon_zulin from '@/assets/img/icon_zulin.png'

import CollectionComp from "./component/collectionComp";
import CartoonItem from './component/cartoonItem/index';
import ShiMingDialog from "@/view/myCollection/component/shiMingDialog";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import classNames from "classnames";

let _activeIndex = 0
let _childrenActiveIndex = 0

const MyCollection = () => {
    const {search} = useLocation()
    //获取参数定位到指定的tab
    const {code = '', subCode = 0, initalTab = 0} = getUrlAllParams(search?.slice(1));
    const {jumpUnity3d = ''} = getUrlAllParams(search.slice(1));

    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [hasMore1, setHasMore1] = useState(false);
    const [maxDay, setMaxDay] = useState(0);
    const [total, setTotal] = useState(0);
    const [carbonTotal, setCarbonTotal] = useState(0);
    const [activeIndex, setActiveIndex] = useState(_activeIndex);
    const [activeBlockIndex, setActiveBlockIndex] = useState(0);
    const [childrenActiveIndex, setChildrenActiveIndex] = useState(_childrenActiveIndex);
    const [childrenBlockActiveIndex, setChildrenBlockActiveIndex] = useState(0);
    const currentPage = useRef(1);
    const currentPage1 = useRef(1);
    const searchCodeRef = useRef(code)
    const subCodeRef = useRef(subCode);

    const [tabs, setTabs] = useState([]);
    const [blockTabs, setBlockTabs] = useState([]);
    const [childBlockCode, setChildBlockCode] = useState('');
    const [childCode, setChildCode] = useState('');
    const [height, setHeight] = useState(355);
    const [collectionCount, setCollectionCount] = useState(0);
    const [walletAddress, setWalletAddress] = useState({});//钱包地址
    const [isShiMing, setIsShiMing] = useState(false);
    const [isLoadUser, setIsLoadUser] = useState(false);
    const [maskVisible, setMaskVisible] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isLoad1, setIsLoad1] = useState(false);
    const [bannerData, setBannerData] = useState({}); //广告位信息
    const [tabIndex, setTabIndex] = useState(parseInt(initalTab));//我的藏品/区块链存证

    useEffect(() => {
        window.JsBridge.setTitle("我的数字资产");
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        setUserInfo(userInfo);
        getMyWallet();
        queryAdvertisement();
        getUserMsg();
        onDidFocus();
    }, []);

    useEffect(() => {
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])
    useEffect(() => {
        return () => {
            _activeIndex = activeIndex
            _childrenActiveIndex = childrenActiveIndex
        }
    }, [activeIndex, childrenActiveIndex])

    const handleScroll = (e) => {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 70) {
        setHeight(260)
      } else {
        setHeight(355)
      }
    }

    const onDidFocus = () => {
      window.JsBridge.addListener().then(() => {
        getMyWallet();
        onDidFocus();
      });
    }

    const getUserMsg = async () => {
        let userInfo0 = window.JsBridge.hasWebViewBridge() ? await window.JsBridge.getCurrentUserInfo() : {};
        if(process.env.NODE_ENV == 'development') {
            userInfo0.clientId = 'xxxx';
        }
        console.log('userInfo0:', userInfo0);
        if (userInfo0 && userInfo0.clientId) { //登录了
            let userInfo1 = window.JsBridge.hasWebViewBridge() ? await window.JsBridge.getDetailCurrentUserInfo() : {};
            if(process.env.NODE_ENV == 'development') {
                userInfo1.ID = 'xxxx';
            }
            setIsLoadUser(true)
            if (!isEmptyString(userInfo1.ID)) { //实名过了
                setIsShiMing(true);
                setMaskVisible(false);
                await getTabData();
                // getBlockTabList();
                await getBlockTabData();
            } else { //没有实名
                setIsShiMing(false);
                setMaskVisible(true);
            }
        } else { //没有登录 去登录
            window.JsBridge.goLogin().then();
            window.JsBridge.addListener().then((result) => {
                getUserMsg();
                reloadUserInfo();
            });
        }
    }

    const getBlockTabData = async () => {
        const tabList = await getBlockTabList();
        let tab = tabList ? tabList[activeBlockIndex] : {}
        let subIndex = 0;
        if (searchCodeRef.current && tabList && initalTab == 1) {
          const index = tabList.findIndex(item => item.code == searchCodeRef.current)
          tab = tabList[index]
          if(tab) {
            const findIndex = tab.childs.findIndex(item => item.code == subCodeRef.current);
            if(findIndex >= 0) {
                subIndex = findIndex;
            }
          }
          setActiveBlockIndex(index)
          setChildrenBlockActiveIndex(subIndex);
          searchCodeRef.current = '';
          subCodeRef.current = '';
        }
        // childBlockCode
        if (tab?.childs && tab.childs.length) {
            let codeStr = tab.childs?.[0].code;
            setChildBlockCode(codeStr);
        }

        const childtab = tab?.childs?.length > childrenBlockActiveIndex ? tab?.childs[subIndex] : {}
        getCarbonList(childtab?.code === '0' ? '' : childtab?.code, tab?.code);
    }

    const getTabData = async () => {
        const tabList = await getTabList();
        let tab = tabList ? tabList[activeIndex] : {}
        let subIndex = 0;
        if (searchCodeRef.current && tabList && initalTab == 0) {
            const index = tabList.findIndex(item => item.code == searchCodeRef.current)
            tab = tabList[index]
            if(tab) {
                subIndex = tab.childs.findIndex(item => item.code == subCodeRef.current);
            }
            setActiveIndex(index)
            setChildrenActiveIndex(subIndex);
            searchCodeRef.current = '';
            subCodeRef.current = '';
        }
        const childtab = tab?.childs?.length > childrenActiveIndex ? tab?.childs[subIndex] : {}
        await getCollectionList(childtab?.code === '0' ? '' : childtab?.code, tab?.code);
        await getCount();
    }

    const getCollectionList = async (collectionIds, seriesId) => {
        if (seriesId == 'all' || seriesId == '00') {
            collectionIds = 'all'
        }
        try {
            showLoading();
            const res = await getCollection({current: 1, size: 10, collectionIds, seriesId: seriesId || 'all'});
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            currentPage.current = 1;
            setList([...data.list]);
            setIsLoad(true);
            setMaxDay(data.maxDay);
            setTotal(data.total);
            setHasMore(data.total > data.list.length);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getCarbonList = async (collectionIds, seriesId) => {
        currentPage1.current = 1;
        if (seriesId == '60') {
            collectionIds = '60';
            seriesId = '';
        }
        try {
            showLoading();
            const res = await getCollection({current: 1, size: 10, collectionIds, seriesId: seriesId});
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            setList1([...data.list]);
            setIsLoad1(true);
            setHasMore1(data.total > data.list.length);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getCount = async () => {
        try {
            const res = await getCollectionCount();
            if (res.code !== '000000') throw res.msg;
            setCollectionCount(res.data.count || 0);
            setCarbonTotal(res.data.blockchainCount || 0)
        } catch (error) {
            Toast.show(error);
        }
    }

    const getMyWallet = async () => {
        try {
            const res = await getMyWalletAddress();
            if (res.code !== '000000') throw res.msg;
            setWalletAddress(res.data)
        } catch (error) {
            Toast.show(error);
        }
    }

    const queryAdvertisement = async () => {
        try {
            const res = await queryAdvertisementByType({type: 22});
            if (res.code !== '000000') throw res.msg;
            setBannerData(res.data || {});
        } catch (error) {
            Toast.show(error);
        }
    }

    const onRefresh = async () => {
        await getCollectionList(tabs[activeIndex].code === '00' ? 'all' : childCode, tabs[activeIndex].code === '00' ? 'all' : tabs[activeIndex]?.code)
    }

    const loadMore = async () => {
        try {
            currentPage.current = currentPage.current + 1;
            const params = {
                current: currentPage.current,
                size: 10,
                collectionIds: tabs[activeIndex].code === '00' ? 'all' : childCode,
                seriesId: tabs[activeIndex].code,
            }
            const {code, msg, data} = await getCollection(params);
            if (code !== '000000') throw msg;
            setList([...list, ...data.list]);
            setIsLoad(true);
            setHasMore(data.total > [...list, ...data.list].length);
        } catch (error) {
            window.JsBridge.print(error);
        }
    }

    const onRefresh1 = async () => {
        await getCarbonList(blockTabs[activeBlockIndex].code === '00' ? 'all' : childBlockCode, blockTabs[activeBlockIndex].code === '00' ? 'all' : blockTabs[activeBlockIndex]?.code)
    }

    const loadMore1 = async () => {
        try {
            currentPage1.current = currentPage1.current + 1;
            const params = {
                current: currentPage1.current,
                size: 10,
                collectionIds: blockTabs[activeBlockIndex].code === '00' ? 'all' : childBlockCode,
                seriesId: blockTabs[activeBlockIndex].code,
            }
            const {code, msg, data} = await getCollection(params);
            if (code !== '000000') throw msg;
            setList1([...list1, ...data.list]);
            setIsLoad1(true);
            setHasMore1(data.list.length >= 10);
            console.log('data.total---', data.list.length)
        } catch (error) {
            window.JsBridge.print(error);
            setHasMore1(false);
        }
    }

    const getTabList = async () => {
        try {
            const {code, msg, data} = await getCollectionTabs({type: 'nftSeries'});
            // const { code, msg, data } = await mockTab();
            if (code !== '000000') throw msg;
            let newTabs = data.map((tabItem) => {
                return {
                    ...tabItem,
                    childs: tabItem.code == '05' ? tabItem.childs : [{
                        codeName: '全部',
                        code: '0'
                    }, ...tabItem.childs]
                }
            })
            newTabs = [{codeName: '全部', code: '00'}, ...newTabs]
            setTabs(newTabs);
            return newTabs
        } catch (error) {
            Toast.show(error);
        }
    }

    const clickParentTab = async (key) => {
        document.documentElement.scrollTop = 0;
        const index = tabs.findIndex((item) => item.code === key)
        setActiveIndex(index);
        setChildrenActiveIndex(0);

        let codeStr = '';

        setChildCode(codeStr);
        setHasMore(false)
        await getCollectionList(key === '00' ? 'all' : codeStr, key === '00' ? 'all' : key);

    }

    const clickChildTab = async (key) => {
        const index = tabs[activeIndex]?.childs?.findIndex((item) => item.code === key)
        setChildrenActiveIndex(index);
        let code = ''
        if (index > 0) {
            code = tabs[activeIndex]?.childs.map((i) => {
                return i.code === '0' ? '' : i.code;
            }).join(',').slice(1);
        }

        const codeStr = key === '0' ? code : key;

        console.log(codeStr);
        setChildCode(codeStr);
        await getCollectionList(codeStr, tabs[activeIndex]?.code);
        document.documentElement.scrollTop = 0;
    }

    const getBlockTabList = async () => {
        try {
            const {code, msg, data} = await getCollectionTabs({type: 'nftBlockchain'});
            // const { code, msg, data } = await mockTab();
            if (code !== '000000') throw msg;
            let newTabs = data.map((tabItem) => {
                return {
                    ...tabItem,
                    childs: [...tabItem.childs]
                }
            })
            newTabs = [...newTabs]
            setBlockTabs(newTabs);
            return newTabs;
        } catch (error) {
            Toast.show(error);
        }
    }

    const clickBlockParentTab = async (key) => {
        document.documentElement.scrollTop = 0;
        const index = blockTabs.findIndex((item) => item.code === key)
        setActiveBlockIndex(index);
        setChildrenBlockActiveIndex(0)
        // console.log('index---', index);
        // console.log('key---', key);
        // console.log('blockTabs---', JSON.stringify(blockTabs));
        //
        // console.log('blockTabs[index]?.childs---', JSON.stringify(blockTabs[index]?.childs));
        let codeStr = '';
        if (blockTabs[index]?.childs && blockTabs[index]?.childs.length) {
            codeStr = blockTabs[index]?.childs?.[0].code;
        }

        console.log('codeStr---', codeStr);

        setChildBlockCode(codeStr)
        setHasMore1(false)
        await getCarbonList(key === '00' ? 'all' : codeStr, key === '00' ? 'all' : key);

    }

    const clickBlockChildTab = async (key) => {
        const index = blockTabs[activeIndex]?.childs?.findIndex((item) => item.code === key)
        // setChildrenActiveIndex(index);
        setChildrenBlockActiveIndex(index)
        let code = ''
        if (index > 0) {
            code = blockTabs[activeBlockIndex]?.childs.map((i) => {
                return i.code === '0' ? '' : i.code;
            }).join(',').slice(1);
        }

        const codeStr = key === '0' ? code : key;

        console.log(codeStr);
        // setChildCode(codeStr);
        setChildBlockCode(codeStr)
        await getCarbonList(codeStr, blockTabs[activeBlockIndex]?.code);
        document.documentElement.scrollTop = 0;
    }


    const jumpYYZ = () => { //跳转到元宇宙
        window.JsBridge.startUnity3d();
    }
    const renderListItem = (item, index) => {
        if (tabs.length > 0 && tabs[activeIndex].code == '05') {
            return <CartoonItem item={{...item, seriesId: tabs[activeIndex].code}} onRefresh={onRefresh}/>
        }
        return <CollectionComp collection={item} maxDay={maxDay} onRefresh={onRefresh}/>
    }

    const renderListItem1 = (item, index) => {
      return <CollectionComp collection={item} maxDay={maxDay} onRefresh={onRefresh}/>
  }

    const btnClick = (id) => {
        if (!isShiMing) {
            shiMingClick();
            return;
        }
        if (id === 0) {
            window.JsBridge.push("Web", {uri: `${window.location.origin}/myGroupOrder`});
        } else if (id === 1) { //链上查询
            window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainQuery`});
        } else if (id === 2) {
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionVote/createCommunity`});
        } else if (id === 3) { //转赠记录
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/transferRecord`});
        } else if (id === 4) { //设置
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/nftSetting`});
        } else if (id === 5) { //dodo社区
            window.JsBridge.push('CustomWeb', {uri: `${decodeURIComponent(bannerData.detailUrl)}?tagUrl=${bannerData.tagUrl}`})
        } else if (id === 6) { //搜索藏品页面
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionSearch`});
        } else if (id === 7) { // 土地租赁
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/landGame/leaseCenter?needRotate=true`});
        }
    }

    const shiMingClick = () => {//实名
        window.JsBridge.navigate('Verify')
        window.JsBridge.addListener().then(() => {
            getUserMsg();
            reloadUserInfo();
        })
    }

    const reloadUserInfo = () => {
        getUserInfo()
            .then(res => {
                setUserInfo(res.data);
                window.localStorage.setItem("userInfo", JSON.stringify(res.data || {}));
            })
            .catch((error) => {
                console.log('请求失败')
            })
    }

    const clickTab = (index) => {
        if (index === tabIndex) {
            return;
        }
        document.documentElement.scrollTop = 0;
        setTabIndex(index);
    }

    const renderItems = () => {
        let arr = [{title: '我的订单', img: icon_dindan, id: 0},
            {title: '链上查询', img: icon_chaxun, id: 1},
            {title: '共创社区', img: icon_shequ, id: 2},
            {title: '转赠记录', img: icon_zhuanzen, id: 3},
            {title: '租赁大厅', img: icon_zulin, id: 7},
        ];
        return arr.map((item, index) => {
            return <div className={'item'} key={index.toString()}
                        onClick={() => btnClick(item.id)}>
                <img src={item.img} alt=""/>
                <p>{item.title}</p>
            </div>
        })
    }

    return (
        <div 
          className={classNames('collection_page', {
            'no_address': !walletAddress?.myWalletAddress || !walletAddress?.spaceNiceNo,
          })} 
          style={list?.length < 3 || list1?.length < 3 ? {height: '100%'} : {}}
        >
            <div className="top" style={{height: height}}>
                <NFTHeader midText="我的数字资产"
                           rightNode={() => <div className="rightHeader">
                               <img onClick={() => btnClick(6)} src={icon_search} alt="" className='search_img'/>
                               <img onClick={() => btnClick(4)} src={icon_shezhi} alt="" className='setting_img'/>
                           </div>}
                />
                <div className="info">
                    <div className="user-info">
                        <div className="avatar"><img src={userInfo?.avatar} alt=""/></div>
                        <div className="right">
                            <div className="name">{userInfo?.name}</div>
                            {!isEmptyString(walletAddress?.myWalletAddress) ? <CopyToClipboard text={walletAddress?.myWalletAddress}
                                                                              onCopy={async () => {
                                                                                  Toast.show('复制成功');
                                                                              }}>
                                <div className="count"><span>区块链地址：{walletAddress?.myWalletAddress}</span> <img src={icon_fuzhi}/></div>
                            </CopyToClipboard> : null}
                            {walletAddress?.spaceNiceNo ? <CopyToClipboard 
                              text={walletAddress?.spaceNiceNo}
                              onCopy={async () => {
                                  Toast.show('复制成功');
                              }}
                            >
                              <div className="beautiful_num count"><span>快捷靓号：{walletAddress?.spaceNiceNo}</span> <img src={icon_fuzhi}/></div>
                            </CopyToClipboard>: null}
                        </div>
                    </div>
                </div>
                <div className="cards-jump">
                    {renderItems()}
                </div>
                {
                    !isEmptyString(bannerData.tinyPicUrl) ?
                        <img src={bannerData.tinyPicUrl} alt="" className={'cards-jump_banner'}
                             onClick={() => btnClick(5)}/> : null
                }

            </div>
            <div>
                <div className={classNames('collection-header', {
                  'no_address': !walletAddress?.myWalletAddress || !walletAddress?.spaceNiceNo,
                })}>
                    {isShiMing ? <div className="titles">
                        <div className='my_col_container' onClick={() => clickTab(0)}>
                            <p className={`my_col_title ${tabIndex === 0 ? 'isChoiceTab' : ''}`}>我的藏品</p>
                            <p className={`my_col_count ${tabIndex === 0 ? 'isChoiceTab' : ''}`}>({collectionCount})</p>
                        </div>
                        <div className='my_col_container' onClick={() => clickTab(1)}>
                            <p className={`my_col_title ${tabIndex === 1 ? 'isChoiceTab' : ''}`}>区块链存证</p>
                            <p className={`my_col_count ${tabIndex === 1 ? 'isChoiceTab' : ''}`}>({carbonTotal})</p>
                        </div>
                    </div> : null}
                    {
                        (isShiMing) ? <div style={{display: tabIndex === 0 ? 'block' : 'none', marginTop: '10px'}}>
                            <div className={activeIndex == 0 || tabs[activeIndex]?.code == '05' ? 'tabs' : ''}>
                                {
                                    <Tabs
                                        activeKey={tabs[activeIndex]?.code}
                                        onChange={clickParentTab}
                                    >
                                        {tabs.map((item) => (
                                            <Tabs.Tab title={<div>{item.codeName}</div>} key={item.code}></Tabs.Tab>
                                        ))}
                                    </Tabs>
                                }
                            </div>
                            {
                                tabs[activeIndex]?.childs && tabs[activeIndex]?.childs.length > 0 &&
                                <div className="child-tabs">
                                    {
                                        <Tabs
                                            activeKey={tabs[activeIndex]?.childs[childrenActiveIndex]?.code}
                                            onChange={clickChildTab}
                                        >
                                            {tabs[activeIndex]?.childs?.map((item) => (
                                                <Tabs.Tab title={<div>{item.codeName}</div>} key={item.code}></Tabs.Tab>
                                            ))}
                                        </Tabs>
                                    }
                                </div>
                            }
                        </div> : null
                    }


                    {
                        (isShiMing) ? <div style={{display: tabIndex === 1 ? 'block' : 'none', marginTop: '10px'}}>
                            <div
                                className={activeBlockIndex == 0 || blockTabs[activeBlockIndex]?.code == '05' ? 'tabs' : ''}>
                                {
                                    <Tabs
                                        activeKey={blockTabs[activeBlockIndex]?.code}
                                        onChange={clickBlockParentTab}
                                    >
                                        {blockTabs.map((item) => (
                                            <Tabs.Tab title={<div>{item.codeName}</div>} key={item.code}></Tabs.Tab>
                                        ))}
                                    </Tabs>
                                }
                            </div>
                            {
                                blockTabs[activeBlockIndex]?.childs && blockTabs[activeBlockIndex]?.childs.length > 0 &&
                                <div className="child-tabs">
                                    {
                                        <Tabs
                                            activeKey={blockTabs[activeBlockIndex]?.childs[childrenBlockActiveIndex]?.code}
                                            onChange={clickBlockChildTab}
                                        >
                                            {blockTabs[activeBlockIndex]?.childs?.map((item) => (
                                                <Tabs.Tab title={<div>{item.codeName}</div>} key={item.code}></Tabs.Tab>
                                            ))}
                                        </Tabs>
                                    }
                                </div>
                            }
                        </div> : null
                    }


                    {
                        (!isShiMing && isLoadUser) ? <div className={'shiming_container'}>
                            <img src={require('@/assets/img/noCollection.png')} alt=""/>
                            <p>{`您还未实名认证\n无法完整使用数字藏品相关功能。`}</p>
                            <p className={'shiming_btn'} onClick={() => shiMingClick()}>去实名</p>
                        </div> : null
                    }
                </div>
                {
                    isShiMing ?
                        <>
                            <div className='collection' style={{display: tabIndex === 0 ? 'block' : 'none',}}>
                                <ListView
                                    dataSource={list}
                                    renderItem={renderListItem}
                                    hasMore={hasMore}
                                    onRefresh={onRefresh}
                                    onEndReached={loadMore}
                                    isLoad={isLoad}
                                    style={{paddingTop: 30}}
                                />
                            </div>
                            <div className='collection' style={{display: tabIndex === 1 ? 'block' : 'none',}}>
                                <ListView
                                    dataSource={list1}
                                    renderItem={renderListItem1}
                                    hasMore={hasMore1}
                                    onRefresh={onRefresh1}
                                    onEndReached={async () => await loadMore1()}
                                    isLoad={isLoad1}
                                    style={{paddingTop: 30}}
                                />
                            </div>
                        </> : null
                }


            </div>
            {!isEmptyString(jumpUnity3d) ? <div className={'enter-meta-com'} onClick={() => jumpYYZ()}>
                <img src={require('@/assets/img/enterMeta.png')}/>
            </div> : null}
            <ShiMingDialog maskVisible={maskVisible} setMaskVisible={setMaskVisible} goShiMing={() => shiMingClick()}/>
        </div>
    )
}

export default MyCollection
