import styles from "./styles.module.scss";
import CascaderDropdownSelect from "./components/cascaderDropdownSelect/index";
import React, {useEffect, useRef, useState} from "react";
import ListView from "@/components/AntListView";
import LeaseItem from "@/view/landGame/leaseCenter/components/leaseItem";
import {hideLoading, showLoading} from "@/components/loading";
import {getGemstoreCount, landApeLease, landApeLeaseDetail, landApeLeaseList} from "@/api/guland";
import {Toast} from "antd-mobile";
import {getLeaseTerm, getOtherSelect} from "@/view/landGame/utils";
import MyModal from "@/view/landGame/jewelLand/modal";
import ModalView from "@/view/landGame/leaseCenter/components/modalView";
import dayjs from "dayjs";
import gem from "@/assets/img/landfi/lease-gem.png";

const OtherLease = () => {
  const domRef = useRef(null);
  const [seriesId, setSeriesId] = useState('all')
  const [collectionIds, setCollectionIds] = useState('all')
  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [options, setOptions] = useState([])
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    getList(true);
  }, [seriesId, collectionIds])

  const getOptions = async () => {
    try {
      const res = await getOtherSelect()
      setOptions(res)
    }catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getOptions()
  }, []);
  const getList = async (isRefresh, loading = true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        collectionType: 3,
        rentalPeriod: -1,
        seriesId,
        collectionIds,
      }
      const res = await landApeLeaseList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setList(res?.data?.list || [])
      } else {
        setList([...list, ...res?.data?.list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.list?.length >= pageSize.current)
    } catch (error) {
      console.log(error)
      hideLoading()
      Toast.show(error)
    }
  }
  const onClickItem = (item) => {
    const ModalInstance = MyModal.show({
      content: <ConfirmLeaseOthers onClose={() => ModalInstance?.close()} infoData={item} refresh={getList}/>,
    })
  }
  return (
    <div className={styles.land_lease}>
      <div className={styles.select_box}>
        <CascaderDropdownSelect
          title="系列"
          options={options}
          onChange={(item, subItem) => {
            if(item) {
              setSeriesId(item?.value)
              if(subItem){
                setCollectionIds(subItem.value)
              }
            }
          }}/>
      </div>
      <div className={styles.land_lease_list} ref={domRef}>
        {
          <ListView
            dataSource={list}
            hasMore={hasMore}
            style={{paddingTop: 5}}
            renderItem={(item) => <LeaseItem item={item} onClick={() => onClickItem(item)} isLand={true}/>}
            onEndReached={async () => {
              await getList(false, false);
            }}
            isLoad={isLoad}
            customStyle={{imgStyle: {width: 150, height: 150}}}
          />
        }
      </div>
    </div>
  )
}
const ConfirmLeaseOthers = ({onClose, infoData, refresh}) => {
  const [landInfo, setLandInfo] = useState({})
  const [gemstoreCounts, setGemstoreCounts] = useState(0)
  const { nftNo, collectionName, caddress, rentalPeriod, rentalFee, nftImage  } = infoData || {}

  useEffect(() => {
    getLeaseDetail()
    queryGemstoreCounts()
  }, [])

  const getLeaseDetail = async () => {
    try {
      const res = await landApeLeaseDetail(nftNo)
      if (res.code !== '000000') throw res.msg
      setLandInfo(res?.data || {})
    } catch (error) {
      console.log(error)
    }
  }

  const confirmLease = async () => {
    if (landInfo.isSelf === 1) return Toast.show('不能租赁自己的藏品');
    if (gemstoreCounts < rentalFee) return Toast.show('宝石不足');
    try {
      showLoading()
      const res = await landApeLease({
        nftNo,
        rentalPeriod,
        rentalFee,
      })
      hideLoading()
      if (res.code !== '000000') throw res.msg
      onClose()
      Toast.show('租赁成功')
      refresh && refresh(true, false)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const queryGemstoreCounts = async () => {
    try {
      const res = await getGemstoreCount()
      if (res?.code !== '000000') throw res?.msg
      setGemstoreCounts(res?.data?.gemstonesCount || 0)
    } catch (error) {
      console.log(error)
    }
  }

  return <ModalView title="确认租赁" onClose={onClose}>
    <div className={styles.confirm_lease_land}>
      <div className={styles.lease_land_top}>
        <div className={styles.land_img}><img src={nftImage} alt="" /></div>
        <div className={styles.land_info}>
          <div className={styles.info_title}>
            <span>{collectionName}</span>
            <span className={styles.common_item}>租期：永久</span>
          </div>
          <div className={`${styles.common_item} ${styles.lease_land_no}`}>编号：{nftNo}</div>
          <div className={`${styles.common_item} ${styles.lease_person}`}>出租人：{caddress}</div>
        </div>
      </div>
      <div className={styles.lease_land_bottom}>
        <div className={styles.gemstore_counts}>当前宝石：{gemstoreCounts}</div>
        <div className={styles.btn_box}>
          <div className={styles.cancel} onClick={onClose}><div className={styles.inner}>取消</div></div>
          <div
            className={`${styles.confirm} ${(gemstoreCounts >= rentalFee && landInfo.isSelf === 0) ? '' : styles.disabled}`}
            onClick={confirmLease}
          >
            <div className={styles.inner}>
              <img src={gem} alt="" />
              <span>{rentalFee} 租赁</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalView>
}
export default OtherLease