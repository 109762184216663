/**
 * @description 订单支付界面
 * @param {collectionId: '',saleBatchId: '', code: 活动码 }
 * @returns {/ape/PayPage}
*/
import {Toast} from "antd-mobile";
import React, {useEffect, useRef, useReducer} from "react";
import {useNavigate, useLocation} from "react-router-dom"
import Error from "@/view/Error/index";
import {getPrepayDetail, checkNotpay, getUserInfo,} from '@/api/projectManager_self';
import NFTHeader from "@/components/NFTHeader";
import {getUrlAllParams} from "@/utils/publicFun";
import {showLoading, hideLoading} from '@/components/loading';
import qs from "query-string";

import ShopEditBox from './component/ShopEditBox'
import TotalInfoBox from './component/TotalInfoBox'
import PayTypeSelect from './component/PayTypeSelect'
import BottomBtn from './component/BottomBtn'
import ReturnPoints from "./component/ReturnPoints";

import "./index.scss"

const FromMap = {
  '19': 'cartoon',
  '31': 'boringApe',
  '72': 'workapeBlindbox',
  '77': 'spaceBeautifulAccount',
}

const IdList = ['31', '72', '77'];
const ReturnPointsList = ['31', '72', '204','209', '238', '239'];

const getPurchaseNum = (id) => {
  let num = 10;
  if (id === '19') {
    num = 6;
  } else if (id === '31' || id === '72') {
    num = 5;
  }
  return num;
}

const PayPage = ()=> {
    const navigate = useNavigate()
    //获取参数
    const {search} = useLocation()
    const param = getUrlAllParams(search.slice(1));
    const {
        stock,
        collectionId = '', //藏品id
        saleBatchId = ''
    } = param
    const [state, dispath] = useReducer((state,action)=> {
        //数据处理
        if(JSON.stringify(state) != JSON.stringify(action)) {
            return {
                ...state,
                ...action
            }
        }
        return state
    }, {
        // 设置state初始值
        errorContext:'',
        userInfo: {},
        payDetail:{},
        payType:'',
        salePrice: 0,
        totalPrice: 0,
        count: 1,
    });

    const notPayOrder = useRef(false)
    const verifyRealName = useRef(true)

    useEffect(()=>{
        if (!collectionId) {
            Toast.show("参数不合法");
            if(process.env.NODE_ENV !== 'development') {
                dispath({errorContext: '参数不合法'})
            }
        } else {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.setTitle('订单支付')
                window.JsBridge.verifyRealName("请先实名认证").then((res)=> {
                    verifyRealName.current = !!res?.result
                })
            }
            loadPageData()
        }
        
    },[])

    //获取用户信息
    const queryUserInfo = async () => {
        const {code, msg, data} = await getUserInfo();
        if (code !== '000000') throw msg;
        return data;
    }
    //获取支付信息
    const getPayDetail = async () => {
        const params = {
            collectionId: collectionId,
            saleBatchId:saleBatchId,
        };
        const {code, msg, data} = await getPrepayDetail(params);
        if (code !== '000000') throw msg;
        return data;
    }
    //有无待支付订单
    const checkIsHasNotPay = async (collectionType) => { 
        const {code, msg, data} = await checkNotpay({collectionType: collectionType || ''});
        if (code !== '000000') throw msg;
        if (data?.notPayOrderCnt) {
            Toast.show("您有待付款的订单，请先完成上次订单")
            notPayOrder.current = true
        }
    }
    const loadPageData = async () => {
        try {
            showLoading();
            const [userInfo, payDetail] = await Promise.all([queryUserInfo(), getPayDetail()]);
            await checkIsHasNotPay(payDetail?.collectionType);
            hideLoading();
            dispath({
                userInfo: userInfo ||{},
                payDetail: payDetail||{}
            })
            
        } catch (error) {
            hideLoading();
            if(process.env.NODE_ENV !== 'development') {
                dispath({errorContext: error})
            }
            Toast.show(error);
        }
    }

    const gotoSalePaySuccess = (list) => {
        if(param.code === 'blindBoxSales'){
          const path = `/blindBox/home` 
          const queryParams = {
            collectionList: JSON.stringify(list),
            from: param.code ?? FromMap[collectionId]
        };
          window.JsBridge.replace("CustomWeb", {
            uri: `${window.location.origin}${path}?${qs.stringify(queryParams)}` 
        });
        }else{
            navigate(`/ape/PaySuccess`, {
                state: {
                    collectionList: list || [], 
                    from: param.code ?? FromMap[collectionId]
                }, 
                replace: true
            })
        }
    }

    const gotoPending = (params) => {
        if(param.code === 'blindBoxSales'){
          const path = `/blindBox/home` 
          const queryParams = {
            collectionList: JSON.stringify(params),
            from: param.code ?? FromMap[collectionId]
        };
            window.JsBridge.replace("CustomWeb", {
              uri: `${window.location.origin}${path}?${qs.stringify(queryParams)}`
          });
        }else{
        navigate(`/ape/PaySuccess`, {
            state: {
                pay_query: params,
                from: param.code ?? FromMap[collectionId]
            }, 
            replace: true
        })
        }

    }

    const gotoOrderList = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.replace("Web", {uri: `${window.location.origin}/myGroupOrder`});
        } else {
            navigate(`/myGroupOrder`, {replace: true})
        }
        
    }


    const renderBody = ()=> {
        return (
            <div className="pay_page--body">
                <div className="body-content">
                    <ShopEditBox userInfo={state.userInfo} 
                        payDetail={state.payDetail}
                        maxCount={getPurchaseNum(collectionId)}
                        showPurchaseMax={!IdList.includes(collectionId)}
                        onPriceChange={(totalPrice, count)=> {
                            dispath({
                                count: count,
                                totalPrice: totalPrice
                            })

                        }}
                    />
                    <TotalInfoBox userInfo={state.userInfo} 
                        payDetail={{...state.payDetail, count: state.count, totalPrice: state.totalPrice}}/>
                    {
                        (ReturnPointsList.includes(collectionId) && state.payDetail.comicsSet && state.payDetail.pointPercentage) ? 
                        <ReturnPoints payDetail={{...state.payDetail, count: state.count, totalPrice: state.totalPrice}}/>
                        : null
                    }
                    <PayTypeSelect onChange={(pay)=>dispath({payType: pay.payType})}/>
                </div>
                <div className="bottom">
                    <BottomBtn userInfo={state.userInfo} 
                        payDetail={{...state.payDetail, count: state.count, totalPrice: state.totalPrice}}
                        param={param}
                        verifyRealName={verifyRealName.current}
                        notPayOrder={notPayOrder.current}
                        payWay={state.payType}
                        callback={(result) => {
                            if(result.code == 'success') {
                                gotoSalePaySuccess(result.data)
                            }else if((result.code == 'pending')){
                                // 不等待支付结果，直接跳转
                                gotoPending(result.data)
                            }else {
                                gotoOrderList()
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="pay_page">
            {
                !window.JsBridge.hasWebViewBridge() &&
                <NFTHeader midText="订单支付" bgColor="white"/>
            }
            {
               state.errorContext ? <Error context={state.errorContext}/>:
                renderBody()
            }
        </div>
    )
}

export default PayPage;