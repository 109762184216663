import requests from '@/utils/requests_self';
import {queryDefCode} from "@/api/projectManager_self";
const mock = false;
class Service {
    static activityCode = 'apeGold';
    static collectionId = 238;
    /**
     * @description 获取返利藏品初始化数据
     * @param {*} params
     * @returns
     */
    static getInitData = async (params={})=> {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
        params = { activityCode: Service.activityCode }
        if(mock) {
            return Promise.resolve({
                code: '000000',
                msg: 'success',
                data: {
                    systemTime: "2024-07-31 19:51:21",
                    startTime: '2024-07-30 10:00:00',
                    endTime: '2024-07-31 10:00:00',
                    requireCount: 140,
                    count: 100,
                    detail: {
                        //0-周，1-月，2-季
                        cycle: 1,
                        list: [
                            {pointCount: 100000, position: 30, cardName: '30日10倍加速卡', cardNum: 1}
                        ]
                    }
                }
            });
        }
        // return await getActivityTime(params);
        return await requests.post('/customer/activity/v1/initActivity', JSON.stringify(params));
    }
    /**
     * @description 获取活动规则
     * @param {*} params
     * @returns
     */
    static getActivityRules = async ()=> {
        return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
    }

    /**
     * @description 获取用户信息，是否实名
     * @returns {ID: 存在则实名}
     */
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }
    /**
     * @description 返还记录
     * @param { * } params
     * @returns
     */
    static getRebateRecord = async (param={})=> {
        const params = {
            activityCode: Service.activityCode,
            ...param,
        }
        return await requests.post(`/customer/activity/v1/collectionRightsRecords?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
    }


}
export default Service