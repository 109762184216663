import Style from "./index.module.scss";
import NFTHeader from "@/components/NFTHeader";
import React, {useEffect, useRef, useState} from "react";
import ListView from "@/components/AntListView";
import {Checkbox, Toast} from "antd-mobile";
import NFTModal from "@/components/NFTModal";
import ConfirmBuyBackModal from "@/view/woodGrainBadge/components/ConfirmBuyBackModal";
import {hideLoading, showLoading} from "@/components/loading";
import Service from "../service";
import {useNavigate} from "react-router-dom";

const BuyBack = () => {
  const navigate = useNavigate();
  const pageDom = useRef(null);
  const [dataSource, setDataSource] = useState([])
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);
  const [value, setValue] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const renderItem = (item) => {
    return (
      <div className={Style.checkCard}>
        <Checkbox value={item.nftNo}>
          <div className={Style.itemBox}>
            <img className={Style.imgBox} src={item.nftImage} alt=""/>
            <div>
              <div className={Style.itemName}>{item.collectionName}</div>
              <div className={Style.itemNo}>{item.nftNo}</div>
              <div className={Style.itemJifenText}>可得积分：<span className={Style.jifenCount}>1000</span></div>
            </div>
          </div>
        </Checkbox>
      </div>
    )
  }
  const loadData = async (isRefesh=false) => {
    const param = {
      pageSize: pageSize.current,
      currentPage: currentPage.current,
      activityCode: Service.activityCode,
    }
    try {
      isRefesh && showLoading();
      const res = await Service.getRecoveryActivity(param);
      isRefesh && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res.data.list || [];
      setTotalCount(res.data.totalCount)
      if(currentPage.current === 1) {
        setDataSource(newList);
        setHasMore(res.data.totalCount > newList.length);
      } else {
        setDataSource([...dataSource, ...newList]);
        setHasMore(res.data.totalCount >[...dataSource, ...newList].length)
      }
    } catch (error) {
      isRefesh && hideLoading();
      Toast.show(error);
    }
  }
  const loadMore = async () => {
    currentPage.current += 1;
    await loadData()
  }
  useEffect(()=>{
    loadData(true)
  },[])
  const handleSelectAll = async () => {
    try {
      showLoading();
      const res = await Service.selectAllRecoveryNftNoList();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.nftNoList?.length > 0) {
        setValue(data.nftNoList)
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      navigate(-1);
    }
  }
  const handleSubmit = () => {
    const ModalInstance = NFTModal.show({
      content: <ConfirmBuyBackModal
        onClose={() => ModalInstance?.close()}
        selectNftNo={value}
        successCallBack={() => {
          ModalInstance?.close()
          onBack()
        }}
      />,
      getContainer: pageDom.current,
    });
  }
  return (
    <div className={Style.container} ref={pageDom}>
      <NFTHeader midText="回购" bgColor={'#FFFFFF'}/>
      <div className={Style.list}>
        <div className={Style.baojiBox}>
          <img src={require('@/assets/img/woodGrainBadge/bellIcon.png')} alt=""/>
          <span className={Style.baojiText}>1%的概率触发暴击！可得 10000 积分！</span>
        </div>
        <div>
          <Checkbox.Group
            value={value}
            onChange={v => {
              setValue(v)
            }}
          >
            <ListView
              dataSource={dataSource}
              renderItem={renderItem}
              hasMore={hasMore}
              isLoad={true}
              style={{paddingTop: 50}}
              onRefresh={()=>{
                setValue([])
                currentPage.current = 1;
                loadData()
              }}
              onEndReached={loadMore}
            />
          </Checkbox.Group>
        </div>
      </div>
      <div className={Style.footerBox}>
        <Checkbox
          indeterminate={value.length > 0 && value.length < totalCount}
          checked={value?.length > 0 && value.length === totalCount}
          onChange={checked => {
            if (checked) {
              handleSelectAll()
            } else {
              setValue([])
            }
          }}
          className={Style.checkAll}
        >
          <span>全选</span>
        </Checkbox>
        <div className={Style.buttonBox}>
          <div className={Style.text}>已选 <span className={Style.colorText}>{value.length}</span> 可获得 <span className={Style.colorText}>{value.length * 1000}</span> 积分</div>
          <button disabled={!(value?.length > 0)} className={value.length > 0 ? Style.button : Style.disabledButton} onClick={handleSubmit}>确认回购</button>
        </div>
      </div>
    </div>
  )
}
export default BuyBack