import requests from '@/utils/requests_self'
import qs from 'query-string'

export async function test() {
    return await requests.get('/nodeServer/todoList')
}

// 获取我的藏品
export const getCollection = async (params) => {
    if (params.seriesId == '05' && !params.collectionIds) {
        // 获取共创漫画所有系列数据
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        params.clientId = userInfo?.clientId || '';
        const res = await requests.post('/customer/comics/getAggComicsList', JSON.stringify(params));
        if (res.data) {
            const list = res.data ?? []
            res.data.list = list
            res.data.total = list.length
        }
        return res;
    }
    return await requests.post('/customer/transfer/v1/getTransferCollectionList', JSON.stringify(params));
}

// 校验转赠信息
export const checkDonationInfo = async (params) => {
    return await requests.post('/customer/transfer/v1/checkBaseInfo', JSON.stringify(params));
}

// 校验被转赠人名字
export const checkName = async (params) => {
    return await requests.post('/customer/transfer/v1/apply', JSON.stringify(params));
}

// 接收藏品页面初始化
export const receivePageInit = async (params) => {
    return await requests.post('/customer/transfer/v1/initCheck', JSON.stringify(params));
}

// 接受转赠
export const receiveDonation = async (params) => {
    return await requests.post('/customer/transfer/v1/acceptGift', JSON.stringify(params));
}

// 拒绝转赠
export const refuseDonation = async (params) => {
    return await requests.post('/customer/transfer/v1/refuseGift', JSON.stringify(params));
}

// 获取验证码
export const getVerificationCode = async (params) => {
    return await requests.post('/customer/dynamic/getCode', JSON.stringify(params));
}

// 获取藏品数量
export const getCollectionCount = async (params) => {
    return await requests.post('/customer/transfer/v1/getMyCollectionCount', JSON.stringify(params));
}

// 查藏品详情
export const queryCollectionDetail = async (params) => {
    return await requests.post('/customer/transfer/v1/getCollectionDetail', JSON.stringify(params));
}

//  获取漫画升级记录
export const getUpgradeRecord = async (params) => {
    return await requests.post('/customer/comics/getUpgradeRecord', JSON.stringify(params));
}

//升级本人聚合漫画接口
export const upgradeComics = async (params) => {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    params.clientId = userInfo?.clientId || '';

    return await requests.post('/customer/comics/upgradeComics', JSON.stringify(params));
}

//共创漫画开盲盒
export const getReceive = async (params) => {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    params.clientId = userInfo?.clientId || '';

    return await requests.post('/customer/receive/v1/getReceive', JSON.stringify(params));
}

// 查询登录手机号
export const queryUserMobile = async (params) => {
    return await requests.get('/customer/base/v1/getUserCode', JSON.stringify(params));
}

// 获取订单列表
export const getOrderList = async (params) => {
    return await requests.post('/customer/transfer/v1/getOrderList', JSON.stringify(params));
}

// 获取订单详情
export const getOrderDetail = async (params) => {
    return await requests.post('/customer/transfer/v1/getOrderDetail', JSON.stringify(params));
}

// 获取订单分组列表
export const getGroupOrderList = async (params) => {
    return await requests.post('/customer/component/v1/getOrderList', JSON.stringify(params));
}

// 获取订单分组详情
export const getGroupOrderDetail = async (params) => {
    return await requests.post('/customer/transfer/v1/getOrderDetail', JSON.stringify(params));
}

// 取消订单
export const cancelOrder = async (params) => {
    return await requests.get(`/customer/order/v1/cancelOrder/${params.outTradeNo}`, JSON.stringify(params));
}

// 获取支付参数
export const getPayParams = async (params) => {
    return await requests.post('/customer/transfer/v1/getOrderParams', JSON.stringify(params));
}

// 获取支付参数 for wx miniProgram
export const getPayParamsForWX = async (params = {
    description: '', tradeNo: '', totalAmount: ''
}) => {
    return await requests.post('/wechat/miniProgram/pay', JSON.stringify(params));
}

// 商品详情
export const getGoodsDetail = async (params) => {
    return await requests.post('/customer/purchase/v1/getCollectionDetail', JSON.stringify(params));
}

// 分享信息
export const getShareInfo = async (params) => {
    return await requests.post('/customer/purchase/v1/getShareConfig', JSON.stringify(params));
}

// 检查是否有待支付订单
export const checkNotpay = async (params) => {
    return await requests.post('/customer/purchase/v1/getPendingPayment', JSON.stringify(params));
}

// 获取预支付信息
export const getPrepayDetail = async (params) => {
    return await requests.post('/customer/purchase/v1/getPrepaid', JSON.stringify(params));
}

// 获取入场券预支付信息
export const getTicketPrepaid = async (params = {
    productId: '', activityCode: '', source: '',
}) => {
    return await requests.post('/customer/purchase/v1/getSimpleProductPrepaid', JSON.stringify(params));
}

// 创建订单
export const createOrder = async (params) => {
    return await requests.post('/customer/order/v1/confirmOrder', JSON.stringify(params));
}

// 创建订单 confirmSimpleOrder
export const confirmSimpleOrder = async (params) => {
    return await requests.post('/customer/order/v1/confirmSimpleOrder', JSON.stringify(params));
}
/**
 * @description 获取nfttoken
 * @param {source: 'mini', token} params
 */ 
export const getNftToken = async (params) => {
    return await requests.post('/nft/v1/generateTokenInfoForH5', JSON.stringify(params));
}

// 获取用户信息
export const getUserInfo = async (params) => {
    return await requests.post('/customer/purchase/v1/getUserInfo', JSON.stringify(params));
}

// 查询支付结果
export const queryPayResult = async (params) => {
    return await requests.get(`/customer/order/v1/getCollectionList/${params.outTradeNo}`, JSON.stringify(params));
}

// 获取猜猜乐订单支付成功返回的数量
export const queryPayResultCount = async (params) => {
    return await requests.get(`/customer/order/v1/getCclOrder/${params.outTradeNo}`);
}

// 兑换
export const exchangeWic = async (params) => {
    return await requests.post('/customer/nft/card/v1/use', JSON.stringify(params));
}

// 获取减时卡列表
export const getRemainTimeCardList = async (params) => {
    return await requests.post('/customer/timeReduce/timeReduceCardEnableList', JSON.stringify(params));
}

// 使用减时卡
export const remainTimeCardUse = async (params) => {
    return await requests.post('/customer/timeReduce/useTimeReduceCard', JSON.stringify(params));
}

// 藏品列表tabs获取
export const getCollectionTabs = async (params) => {
    return await requests.get(`/customer/collections/series/getSeriesList/${params.type}`, JSON.stringify(params));
}

// 转赠记录
export const queryTransferRecord = async (params) => {
    return await requests.post('customer/transfer/v1/getTransferList', JSON.stringify(params));
}

// 内购验证
export const iapValidatePayload = async (params) => {
    return await requests.post('/customer/iap/purchase/v1/validatePayload', JSON.stringify(params));
}

// 藏品预览
export const previewImage = async (params) => {
    return await requests.get(`/customer/preview/v1/previewImage/${params.nftNo}`, JSON.stringify(params));
}

// 购物车首页列表 不用登陆的
export const getComponentListNoLogin = async (params) => {
    return await requests.post(`/customer/component/v1/getComponentListNoLogin`, JSON.stringify(params));
}
// 购物车首页列表
export const getComponentList = async (params) => {
    return await requests.post(`/customer/component/v1/getComponentList`, JSON.stringify(params));
}
// 购物车首页添加商品
export const addPurchase = async (params) => {
    return await requests.post(`/customer/component/v1/addPurchase`, JSON.stringify(params));
}
// 购物车列表
export const getPurchaseList = async (params) => {
    return await requests.post(`/customer/component/v1/getPurchaseList`, JSON.stringify(params));
}
// 购物车规则
export const queryDefCode = async (params) => {
    return await requests.post(`/customer/component/v1/queryDefCode`, JSON.stringify(params));
}
// 清空购物车
export const deleteAllPurchaseList = async (params) => {
    return await requests.post(`/customer/component/v1/deleteAllPurchaseList`, JSON.stringify(params));
}
// 购物车修改数量
export const changePurchase = async (params) => {
    return await requests.post(`/customer/component/v1/changePurchase`, JSON.stringify(params));
}
// 获取购物车预支付信息
export const getShopPrepayDetail = async (params) => {
    return await requests.post('/customer/component/v1/getPrepaid', JSON.stringify(params));
}
// 获取购物车的数量
export const getShoppingNum = async (params) => {
    return await requests.get(`/customer/component/v1/getShoppingNum/${params.clientId}`, JSON.stringify(params));
}
// 购物车下单
export const shopConfirmOrder = async (params) => {
    return await requests.post(`/customer/order/v1/batch/confirmOrder`, JSON.stringify(params));
}
// 获取碎片兑换记录
export const shopConvertRecord = async (params) => {
    return await requests.post(`/customer/component/v1/convertRecord`, JSON.stringify(params));
}
// 获取产品id
export const getProductList = async (params) => {
    return await requests.post(`/customer/iap/purchase/v1/getProductList`, JSON.stringify(params));
}
// 孵化预览
export const hcImageList = async (params) => {
    return await requests.post(`/customer/compose/v1/imageList`, JSON.stringify(params));
}
// 孵化合成
export const hcImageV1 = async (params) => {
    return await requests.post(`/customer/compose/v1`, JSON.stringify(params));
}
// 获取孵蛋合成的结果
export const getComposeResult = async (params) => {
    return await requests.get(`/customer/compose/v1/getComposeResult/${params.req}`, JSON.stringify(params));
}
// 获取稀有度的
export const selRarityList = async (params) => {
  if (params.clientId){
    return await requests.get(`/customer/preview/v1/selRarityList/${params.nftHash}/${params.clientId}`, JSON.stringify(params));
  }else {
    return await requests.get(`/customer/preview/v1/selRarityList/${params.nftHash}`, JSON.stringify(params));
  }
    // return await requests.get(`/customer/preview/v1/selRarityList/${params.nftHash}`, JSON.stringify(params));
}
// 是否能合成
export const getHcStatus = async (params) => {
    return await requests.get(`/customer/preview/v1/getCollection/${params.nftHash}`, JSON.stringify(params));
}

// 孵化安卓的状态
export const getswitchStatus = async (params) => {
    //这个接口设计的很垃圾啊
    return await requests.get(`/customer/preview/v1/getswitchStatus/${params.code}`, JSON.stringify(params));
}
/**
 * @description 藏品详情获取合成按钮开关状态接口
 * @param {activityCode: 活动码, collectionId } params
 * @returns {
 *      status: 合成按钮状态 1可以 其他不行,
 *      requireCount: 需要的数量,康养藏品必需,
 *      count: 当前持有的数量,康养藏品必需,
 * }
*/
export const getMergeSwitchStatus = async (params) => {
    return await requests.post(`/customer/preview/v1/getSwitchStatus`, JSON.stringify(params));
}

// 问卷调查提交
export const voteCommit = async (params) => {
    return await requests.post(`/customer/cocreation/vote/commit`, JSON.stringify(params));
}

// 问卷调查初始化
export const voteInit = async (params) => {
    return await requests.post(`/customer/cocreation/vote/init`, JSON.stringify(params));
}

// 获取共创征集意见列表
export const voteWeightList = async (params) => {
    return await requests.post(`/customer/cocreation/vote/listWeight`, JSON.stringify(params));
}

// 变异无聊猿活动初始化
export const getVariationApeActivityInfo = async (params = {}) => {
    return await requests.post(`/customer/boringApe/synthesis/indexInit`, JSON.stringify(params));
}

// 变异无聊猿药水合成
export const variatonApeCompose = async (params) => {
    return await requests.post(`/customer/mutational/collectionsSynthetic`, JSON.stringify(params));
}

// 查询指定类型藏品
export const queryAppointTypeCollection = async (params) => {
    return await requests.post(`/customer/mutational/getCollectionsByType`, JSON.stringify(params));
}

// 查询变异记录
export const queryComposeReocrd = async (params) => {
    return await requests.post(`/customer/mutational/getSyntheticRecord`, JSON.stringify(params));
}

// 获取我的钱包地址
export const getMyWalletAddress = async (params) => {
    return await requests.post(`/customer/blockchain/v1/getMyWalletAddress`, JSON.stringify(params));
}

// 判断查询内容是区块链地址还是交易哈希
export const getChainQueryType = async (params) => {
    return await requests.post(`/customer/blockchain/v1/getChainQueryType`, JSON.stringify(params));
}

// 查询区块链地址的TA的交易
export const getBlockChainTransactions = async (params) => {
    return await requests.post(`/customer/blockchain/v1/getBlockChainTransactions`, JSON.stringify(params));
}

// 查询区块链地址的TA的资产
export const getBlockChainAssets = async (params) => {
    return await requests.post(`/customer/blockchain/v1/getBlockChainAssets`, JSON.stringify(params));
}
// 查询区块链藏品信息
export const queryTransactionHash = async (params) => {
    return await requests.post(`/customer/blockchain/v1/queryTransactionHash`, JSON.stringify(params));
}
// 邀新记录
export const getInviteRecord = async (params) => {
    return await requests.post(`/customer/boringApe/synthesis/getInviteRecordList`, JSON.stringify(params));
}

// 变异无聊猿排行榜
export const getPurchaseRanking = async () => {
    return await requests.post(`/customer/mutational/getPurchaseRanking`, JSON.stringify());
}

// 查询当前用户的藏品设置信息
export const getUserCollectionsSetting = async (params) => {
    return await requests.get(`/customer/userCollectionsSetting/v1/getUserCollectionsSetting`, JSON.stringify(params));
}

// 设置是否隐藏藏品交易信息
export const setUserIsShowTradeinfo = async (params) => {
    return await requests.post(`/customer/userCollectionsSetting/v1/isShowTradeinfo`, JSON.stringify(params));
}

// 保存修改藏品交易密码
export const settingPassword = async (params) => {
    return await requests.post(`/customer/userCollectionsSetting/v1/settingPassword`, JSON.stringify(params));
}

// 校验输入密码是否正确
export const checkUserPassword = async (params) => {
    return await requests.post(`/customer/userCollectionsSetting/v1/checkUserPassword`, JSON.stringify(params));
}

// 校验区块链地址
export const checkWalletAddress = async (params) => {
    return await requests.post(`/customer/blockchain/v1/checkWalletAddress`, JSON.stringify(params));
}

//根据广告类型查询广告信息
export const queryAdvertisementByType = async (params) => {
    return await requests.post(`/customer/advertisement/v1/queryAdvertisementByType`, JSON.stringify(params));
}

// 配件回收init
export const partsRecyclingInit = async () => {
    return await requests.get(`/customer/recoveryAccessory/v1/init`);
}

// 配件回收 开奖
export const openScratchCard = async (params) => {
    return await requests.post(`/customer/recoveryAccessory/v1/exec/${params}`);
}

// 配件回收记录
export const partsRecyclingRecord = async (params) => {
    return await requests.get(`/customer/recoveryAccessory/v1/getRecoveryRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}

// 获取奖池积分
export const queryJackpotPoint = async () => {
    return await requests.get(`/customer/recoveryAccessory/v1/getPrizePool`);
}
// 回收配件接口
export const recyclingParts = async (params) => {
    return await requests.post(`/customer/recoveryAccessory/v1/rdo`, JSON.stringify(params));
}
// 查询配件列表
export const queryPartsList = async (params) => {
    return await requests.get(`/customer/recoveryAccessory/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}
// 获取中奖记录
export const getDrawRecord = async (params) => {
    return await requests.get(`/customer/recoveryAccessory/v1/getDrawRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}
// 获取全部场次列表
export const getSessionList = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getSessionList`);
}
// 回收首页初始
export const quizCouponMainInit = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/quizCouponMainInit?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
    // return await requests.get(`/customer/secondaryVariantApe/quizCouponMainInit`);
}
// 回收获取兑换券
export const recoveryEpic = async (params) => {
    return await requests.post(`/customer/secondaryVariantApe/recoveryEpic`, JSON.stringify(params));
}
// 获取用户某一场的答题记录
export const getSessionRecord = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getSessionRecord/${params.session}`);
}
// 用户参加竞猜
export const participateQuizzes = async (params) => {
    return await requests.post(`/customer/secondaryVariantApe/participateQuizzes`, JSON.stringify(params));
}
// 获取用户本轮可以参加投票的数量
export const getSignNum = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getSignNum/${params.session}/${params.round}`);
}
//主页初始化
export const secondaryVariantApeInit = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/mainInit`);
}
//获取当前题目
export const getGuessingQuestions = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getGuessingQuestions`);
}
// 获取用户竞猜/奖励记录
export const getQuizCouponMainInit = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/participateQuizzes?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}
// 获取用户碎片
export const getWealth = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getWealth`);
}
// 获取次级无聊猿的商品列表
export const getGoodslist = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getGoodslist/${params.preview}`);
}
// 获取兑换商品的库存
export const getGoodsStock = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getGoodsStock/${params.goodsId}`);
}
// 3、兑换商品
export const goodsExchange = async (params) => {
    return await requests.post(`/customer/secondaryVariantApe/goodsExchange`, JSON.stringify(params));
}
// 藏品搜索页面列表
export const getSearchCollectionSerialList = async () => {
  // 无参
  return await requests.post('/customer/collection/search/series-config-list', JSON.stringify({}));
}
// 藏品导航页
export const getNavigationSerialCollectionList = async (params) => {
  // {current: currentPage.current, size: 10, collectionIds, seriesId:"" }
  return await requests.post('/customer/collection/search/collection-list', JSON.stringify(params));
}
// 藏品搜索结果页
export const getSearchResult = async (params) => {
  // clientId,collectionIds,current,size
  return await requests.post('/customer/collection/search/fuzzy-query', JSON.stringify(params));
}
// 藏品搜索页面列表
export const getCode = async () => {
  // 无参
  return await requests.get(`/jkt/nft/value/v1/show-type/pointLottery`);
}
// 兑换记录的列表
export const getQuizCouponList = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getQuizCouponList/${params.type}?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}
// 我的卷包
export const getCouponList = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/getCouponList/${params.type}?currentPage=${params.currentPage}&pageSize=${params.pageSize}`);
}

// 获取排行榜
export const getRankingList = async (params) => {
    return await requests.get(`/customer/secondaryVariantApe/rankingList/${params.type}`);
}
// 添加用户观演人信息
export const addTicketUserInfo = async (params) => {
    return await requests.post('/api/collection/point/lottery/add-ticket-user-info', JSON.stringify(params));
}
// 元宇宙配件回收 开奖
export const unityOpenScratchCard = async (params, bParams) => {
  return await requests.post(`/customer/recoveryActivity/v1/exec/${params}`, JSON.stringify(bParams));
}
// 中奖记录
export const getPrizeRecord = async (params, pagingParams) => {
  return await requests.post(`/customer/recoveryActivity/v1/getDrawRecord?${qs.stringify(pagingParams)}`, JSON.stringify(params));
}
// 配件回收unity 主页初始化
export const recoveryActivityInit = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/init`, JSON.stringify(params));
}
// 配件回收unity 奖池信息
export const getPrizePoolUnity = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/getPrizePool`, JSON.stringify(params));
}
// 配件回收unity 获取回收记录
export const getRecoveryRecord = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/getRecoveryRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
}

// 配件回收unity 用户可以回收的藏品列表
export const recoveryActivityList = async (params,params1) => {
    return await requests.post(`/customer/recoveryActivity/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params1));
}
// 配件回收unity 用户回收藏品
export const recoveryActivityRdo = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/rdo`, JSON.stringify(params));
}

// 配件回收unity 获取回收剩余次数
export const getRemainCount = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/getRemainCount`, JSON.stringify(params));
}

// 查询本人需要合成的碎片列表
export const getImageList = async (params) => {
    return await requests.post(`/customer/debris/v1/imageList`, JSON.stringify(params));
}

// 合成碎片接口
export const debrisCompose = async (params) => {
    return await requests.post(`/customer/debris/v1/compose`, JSON.stringify(params));
}
// 判断是否需要看合成的按钮
export const itCanBeSynthesized= async (params) => {
    return await requests.get(`/customer/debris/v1/${params.petNo}/${params.collectionId}`);
}


// 获取活动时间
export const getActivityTime= async (params) => {
    return await requests.get(`/customer/recoveryActivity/v2/getActivityTime/${params.activityCode}`);
}
// 获取用户剩余回收次数
export const getRemainCountv2 = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/getRemainCount`, JSON.stringify(params));
}
// 获取用户回收的藏品列表
export const getRecoveryActivity = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
}
// 回收藏品
export const recoveryActivityExec = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/exec`, JSON.stringify(params));
}
// 回收记录
export const getRecoveryRecordv2 = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/getRecoveryRecord`, JSON.stringify(params));
}
export const getOpenRecord = async (params) => {
    return await requests.post(`/customer/order/v1/unboxingRecord`, JSON.stringify(params));
}
// 合成记录
export const getComposeRecord = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/getComposeRecord`, JSON.stringify(params));
}
// 获取用户元素列表
export const getElementCountList= async (params) => {
    return await requests.get(`/customer/recoveryActivity/v2/getElementCountList`);
}
// 元素合成
export const recoveryActivityCompose= async (params) => {
    return await requests.post(`/customer/recoveryActivity/v2/compose`, JSON.stringify(params));
}
// 获取用户购买记录
export const getPurchaseRecord= async (params) => {
    return await requests.get(`/customer/recoveryActivity/v2/getPurchaseRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
}
//获取用户实名状态
export const getUserCertificate= async (params) => {
    return await requests.get(`/customer/base/v1/getUserCertificate`);
}

// 查询矿工猴藏品数量及回收次数
export const getAbleRecCount = async (params) => {
  return await requests.post(`/customer/recoveryActivity/v1/getAbleRecCount`, JSON.stringify(params));
}

// 查询矿工猴藏品回收活动配置
export const getRecConfig = async (params) => {
  return await requests.post(`/customer/recoveryActivity/v1/getRecConfig`, JSON.stringify(params));
}
// land藏品回收-获取藏品列表
export const getRecoveryActivityList = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
}

// land藏品回收-藏品回收
export const recoveryActivitySimpleRdo = async (params) => {
    return await requests.post(`/customer/recoveryActivity/v1/simpleRdo`, JSON.stringify(params));
}
// 工业猴-活动主页初始化
export async function industrialApeActivity(params) {
    return await requests.post('/customer/activity/v1/initActivity', JSON.stringify(params))
  }
  
  // 工业猴-合成
  export async function industrialApeExec(params) {
    return await requests.post('/customer/recoveryActivity/v2/exec', JSON.stringify(params))
  }
  
  // 工业猴-获取可以回收的藏品配件列表
  export async function industrialApeList(params) {
    return await requests.post(`/customer/recoveryActivity/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params))
  }
  
  // 工业猴-批量获取用户合成的藏品数量
  export async function industrialApeSeriesCount(params) {
    return await requests.post(`/customer/activity/v1/getSeriesCount`, JSON.stringify(params))
  }
  
  // 工业猴-合成记录列表
  export async function industrialApeRecoveryRecord(params) {
    return await requests.post(`/customer/recoveryActivity/v2/getRecoveryRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params))
  }
  
  // 王小慧获取奖池接口
  export async function getActivityNftPool(params) {
    return await requests.post(`/customer/activity/v1/getActivityNftPool`, JSON.stringify(params))
  }