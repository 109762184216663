/**
 * @description ape world 配件盲盒
 * @date 2024-07-25
*/
import Home from './home';
import OpenHistory from './openHistory';
import BlindBoxShare from './blindBoxShare';
import BlindBoxInviteSuccess from './blindBoxInviteSuccess';
export default {
    Home,
    OpenHistory,
    BlindBoxShare,
    BlindBoxInviteSuccess,
}