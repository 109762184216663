import React, {useState} from "react";
import './index.scss'
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import Service from '../../service'

/**
 * @param onClose
 * @param type one | all
 * @param toSeeCollections
 * @return {Element}
 * @constructor
 */
const CompoundConfirmModal = ({ onClose, type='one', toSeeCollections, goldCount, requiredCount, successCallBack }) => {
  const [success, setSuccess] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState({})

  const handleCompoundOk = async () => {
    try {
      showLoading()
      const res = await Service.compoundService({
        type: type=== 'one' ? 1 : null
      })
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      if (res.data) {
        setCollectionInfo(res.data)
        setSuccess(true)
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  const toDetail = () => {
    onClose()
    toSeeCollections && toSeeCollections?.()
  }
  const handleCancel = () => {
    if(success){
      successCallBack && successCallBack()
    }
    onClose()
  }
  return (
    <div className="modalContainer-compound">
      <div className="box-compound">
        <div className="title-compound">{success ? '合成成功' : '合成藏品'}</div>
        <div className="content-compound">
          {success
            ? <div>
              <img className="centerImg-compound" src={collectionInfo?.urlList?.length > 0 ? collectionInfo?.urlList[0] : ''} alt=""/>
              {type === 'all' && <div className="text3-compound">x{collectionInfo?.userCollectionRespList?.length}</div>}
            </div>
            : type === 'all'
              ? <div>
                <div className="text2-compound">当前最多可合成 {goldCount} 枚金色徽章</div>
                <div>
                  <div className="text1-compound">合成可得{goldCount}枚金色徽章</div>
                  <div className="text1-compound">确认将{goldCount * requiredCount}个木纹徽章合成吗？</div>
                  <div className="text1-compound">合成后木纹徽章将被销毁</div>
                </div>
              </div>
              : <div>
                <div className="one-text1-box">
                  <div className="text1-compound">合成可得1枚金色徽章</div>
                  <div className="text1-compound">确认将{requiredCount}个木纹徽章合成吗？</div>
                  <div className="text1-compound">合成后木纹徽章将被销毁</div>
                </div>
              </div>
          }
        </div>
        <div className="btnBox-compound">
          <div className="btnFlex">
            <button className="cancelBtn-compound" onClick={handleCancel}>取消</button>
          </div>
          <div className="divider-compound"/>
          <div className="btnFlex">
            {success ?
              <button className="confirmBtn-compound" onClick={toDetail}>查看藏品</button> :
              <button className="confirmBtn-compound" onClick={handleCompoundOk}>确认合成</button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CompoundConfirmModal