import React, { useEffect, useMemo, useState } from "react";
import { Mask } from "antd-mobile";
import {useNavigate} from "react-router-dom"
import styles from "./styles.module.scss";
import close from "@/assets/img/landfi/icon_del.png";
import land from "@/assets/img/landfi/rent-1.png";
import ape from "@/assets/img/landfi/rent-4.png";
import flower from "@/assets/img/landfi/rent-3.png"

const RentOut = ({ visible, onclose, container }) => {
  const navigate = useNavigate();
  const toDetail = (type) =>{
    // if (window.JsBridge.hasWebViewBridge()) {
    //   window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/landGame/rentOutDetail`});
    // } else {
    //   navigate(`/landGame/rentOutDetail`);
    // }
    navigate(`/landGame/rentOutDetail`,{state: {type: type}});

  }
  return (
    <Mask opacity='thick' className={styles.rent_out_mask} visible={visible} destroyOnClose>
      <div className={styles.land_rent_out}>
        <img
          className={styles.close}
          src={close}
          alt=""
          onClick={() => onclose()}
        />
        <div className={styles.box}>
          <div className={styles.title}>请选择</div>
          <div className={styles.info}>
            <div className={styles.item}>
              <img src={land} alt="" onClick={() => {
                toDetail(2)
              }} />
              <span className={styles.item_info}>土地</span>
            </div>
            <div className={styles.item}>
              <img src={ape} alt="" onClick={() => {
                toDetail(1)
              }} />
              <span className={styles.item_info}>无聊猿</span>
            </div>
            <div className={styles.item}>
              <img src={flower} alt="" onClick={() => {
                toDetail(3)
              }} />
              <span className={styles.item_info}>其他</span>
            </div>
          </div>
        </div>
      </div>
    </Mask>
  );
};

export default RentOut;
